define('em-city/game/ec-security-unit', ['exports', 'em-city/game/ec-unit'], function (exports, _ecUnit) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var MAX_SECURITY = 4;
  var MIN_SECURITY = 2;

  var COLOR_1 = 0x3A6BB7;
  var COLOR_2 = 0xB73A3A;

  var ECSecurityUnit = function ECSecurityUnit(orientationIndex) {
    _ecUnit.default.call(this, orientationIndex);
    this.name = 'security-unit';
    this.value = THREE.Math.randInt(MIN_SECURITY, MAX_SECURITY);
  };

  ECSecurityUnit.prototype = Object.create(_ecUnit.default.prototype);
  ECSecurityUnit.prototype.constructor = ECSecurityUnit;
  ECSecurityUnit.prototype.COLOR_1 = COLOR_1;
  ECSecurityUnit.prototype.COLOR_2 = COLOR_2;

  exports.default = ECSecurityUnit;
});