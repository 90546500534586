define("em-city/game/ec-directional-light", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var LIGHT_COLOR = 0xFFFFFF;
  var LIGHT_INTENSITY = 0.5;
  var POSITION = [0.5, 1.0, 0.5];

  var ARGS = [LIGHT_COLOR, LIGHT_INTENSITY];

  var ECDirectionalLight = function ECDirectionalLight() {
    var _position;

    THREE.DirectionalLight.apply(this, ARGS);
    (_position = this.position).set.apply(_position, POSITION);
    this.castShadow = false;
  };

  ECDirectionalLight.prototype = Object.create(THREE.DirectionalLight.prototype);
  ECDirectionalLight.prototype.constructor = ECDirectionalLight;

  exports.default = ECDirectionalLight;
});