define('em-city/services/game', ['exports', 'em-city/util/animate-value-task', 'em-city/game/ec-camera', 'em-city/game/ec-renderer', 'em-city/game/touch-handler', 'em-city/game/scene-manager', 'em-city/game/cursor-manager', 'em-city/game/game-actions', 'em-city/game/sound-effects', 'em-city/game/property-manager', 'em-city/game/game-loop', 'em-city/game/sector-visual', 'em-city/game/constants', 'em-city/game/game-action-animations'], function (exports, _animateValueTask, _ecCamera, _ecRenderer, _touchHandler, _sceneManager, _cursorManager, _gameActions, _soundEffects, _propertyManager, _gameLoop, _sectorVisual, _constants, _gameActionAnimations) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var NUM_BUSTED_EM_TOWERS_FOR_EXPLOSION = 3;

  exports.default = Ember.Service.extend({
    router: Ember.inject.service(),
    backgroundColor: Ember.computed(function () {
      return this.sceneManager.backgroundColor;
    }).volatile(),

    backgroundColorStyle: Ember.computed(function () {
      return this.sceneManager.backgroundColorStyle;
    }).volatile(),

    name: Ember.computed('copyNumber', function () {
      return 'Copy' + this.get('copyNumber');
    }),

    gameState: Ember.computed(function () {
      return JSON.parse(localStorage.getItem('gameState'));
    }).volatile(),

    serializedGame: Ember.computed(function () {
      var propMap = this.get('propMap');
      var serialized = {};
      serialized.propMap = {
        calendarTime: propMap.calendarTime,
        sceneTime: propMap.sceneTime,
        timeLeft: propMap.timeLeft
      };
      return Object.assign(serialized, this.get('sceneManager').serialized);
    }).volatile(),

    copyNumber: THREE.Math.randInt(2, 16777216),

    animateSceneUpdateTask: _animateValueTask.default,

    init: function init() {
      this.set(_constants.INITIAL_GAME_CONFIGURATION_COMPLETE, false);
      this.set('onTop', false);
      this.renderFrame = renderFrame.bind(this);
      this.gameLoop = (0, _gameLoop.default)(_constants.MS_PER_UPDATE, updateFunction, this);
      this.camera = new _ecCamera.default();
      this.sceneManager = new _sceneManager.default(this.camera);
      this.propertyManager = new _propertyManager.default();
      this.set('propMap', Ember.Object.create(_propertyManager.default.INITIAL_PROPERTY_MAP));
      this.soundEffects = new _soundEffects.default();
      this.actions = new _gameActions.default(this.sceneManager, this.completeTurn.bind(this));
      this.currentSceneTime = this.get('propMap').get('sceneTime');
      this.set('visual', new _sectorVisual.default());
      window.addEventListener('resize', this.resize.bind(this), false);
    },
    prepareGame: function prepareGame() {
      var _this = this;

      this.renderer = new _ecRenderer.default();
      this.canvas = this.renderer.domElement;
      this.cursorManager = new _cursorManager.default(this.canvas);
      this.configureInput();
      this.sceneManager.downloadAssets().then(function () {
        return _this.configureGame();
      });
    },
    reset: function reset() {
      this.configureSceneAndProperties();
      this.saveGameState();
      this.start();
    },
    configureSceneAndProperties: function configureSceneAndProperties() {
      cancelAnimationFrame(this.get('animationFrame'));
      this.set('won', false);
      this.set('ranOutOfTime', false);
      this.sceneManager.configureScene();
      this.propertyManager.configure(this.sceneManager.ground);
      this.get('propMap').setProperties(_propertyManager.default.INITIAL_PROPERTY_MAP);
    },
    configureGame: function configureGame() {
      this.configureSceneAndProperties();

      if (!this.get('gameState')) {
        this.saveGameState();
      } else {
        this.loadGameState();
      }

      this.checkEndGameConditions();
      this.set(_constants.INITIAL_GAME_CONFIGURATION_COMPLETE, true);
      this.start();
    },
    saveGameState: function saveGameState() {
      var gameState = this.get('serializedGame');
      localStorage.setItem('gameState', JSON.stringify(gameState));
    },
    loadGameState: function loadGameState() {
      var gameState = this.get('gameState');
      var propMap = this.get('propMap');
      this.sceneManager.loadGameState(gameState);
      propMap.set('calendarTime', gameState.propMap.calendarTime);
      propMap.set('sceneTime', gameState.propMap.sceneTime);
      propMap.set('timeLeft', gameState.propMap.timeLeft);
      this.sceneManager.updateScene(propMap.get('sceneTime'));
    },
    configureInput: function configureInput() {
      this.controls = new THREE.OrbitControls(this.camera, this.canvas);
      this.controls.minZoom = _constants.MIN_ZOOM;
      this.controls.maxZoom = _constants.MAX_ZOOM;

      this.touchHandler = new _touchHandler.default(this.camera, this.canvas, this.playerTapped.bind(this));
    },
    playerTapped: function playerTapped(square) {
      if (!square) return;
      var index = this.sceneManager.indexOfSquare(square);
      this.get('router').transitionTo('play.sector', index);
    },
    start: function start() {
      this.resize();
      this.animationFrame = requestAnimationFrame(this.renderFrame);
      this.updateProperties(false);
      this.configureTouchableObjects();
    },
    updateProperties: function updateProperties() {
      var updateTime = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : true;

      var propMap = this.get('propMap');
      propMap.setProperties(this.propertyManager.updatedProperties(propMap, updateTime));
    },
    completeTurn: function completeTurn() {
      this.updateProperties(true);
      this.updateGameObjectsAfterTurn();
      this.updateProperties(false);

      this.configureTouchableObjects();
      this.checkEndGameConditions();
      this.saveGameState();
    },
    checkEndGameConditions: function checkEndGameConditions() {
      this.winGame(this.sceneManager.scene, this.sceneManager.ground);
      this.runOutOfTime();
    },
    configureTouchableObjects: function configureTouchableObjects() {
      this.touchHandler.configureTouchableObjects(this.sceneManager.touchableObjects);
    },
    handleHighlights: function handleHighlights() {
      if (!this.touchHandler) return;
      this.sceneManager.unhighlightEverything();
      this.sceneManager.highlightSquare(this.touchHandler.squareToHighlight);
    },
    updateControls: function updateControls() {
      if (!this.controls) return;
      this.cursorManager.update(!!this.touchHandler.touchedSquare);
    },
    resize: function resize() {
      this.dimensions = windowDimensions();
      this.renderer.scaleWithDimensions(this.dimensions);
      this.sceneManager.scaleWithDimensions(this.renderer.dimensions);
      this.camera.updateWithScaledDimensions(this.renderer.dimensions, this.sceneManager.scaleFactor);
    },
    turnOnDemoMode: function turnOnDemoMode() {
      this.sceneManager.turnOnDemoMode();
    },
    turnOffDemoMode: function turnOffDemoMode() {
      this.sceneManager.turnOffDemoMode();
    },
    winGame: function winGame(scene, ground) {
      var _this2 = this;

      if (this.get('won')) return;
      if (this.get('propMap').production < _constants.WINNING_PRODUCTION_RATE) return;
      (0, _gameActionAnimations.winAnimation)(scene, ground, function () {
        return _this2.set('won', true);
      });
      this.get('soundEffects').play('intro');
    },
    runOutOfTime: function runOutOfTime() {
      var timeLeft = this.get('propMap.timeLeft');
      if (timeLeft <= 0) {
        this.set('ranOutOfTime', true);
        this.set('copyNumber', this.get('copyNumber') + 1);
      }
    },
    squareForIndex: function squareForIndex(index) {
      return this.sceneManager.squareForIndex(index);
    },
    updateGameObjectsAfterTurn: function updateGameObjectsAfterTurn() {
      var sceneManager = this.get('sceneManager');
      var propMap = this.get('propMap');
      var emCubes = sceneManager.emCubes;
      var squares = sceneManager.squares;

      updateEmCubes(emCubes, propMap);
      bustEmCubes(emCubes);
      blowUpEmTowers(squares, sceneManager);
    }
  });


  function renderFrame(timestamp) {
    this.animationFrame = requestAnimationFrame(this.renderFrame);
    if (this.stats) this.stats.begin();
    this.gameLoop(timestamp);
    this.actions.timestamp = timestamp;
    this.updateControls();
    this.handleHighlights();

    this.renderer.render(this.sceneManager.scene, this.camera);
    if (this.stats) this.stats.end();
  }

  function updateFunction(timestamp) {
    var _this3 = this;

    this.sceneManager.animate(timestamp, this.camera);
    TWEEN.update();
    var currentSceneTime = this.get('currentSceneTime');
    var updatedSceneTime = this.get('propMap').get('sceneTime');

    if (currentSceneTime !== updatedSceneTime) {
      this.set('currentSceneTime', updatedSceneTime);
      this.get('animateSceneUpdateTask').perform(currentSceneTime, updatedSceneTime, function (updatedValue) {
        return _this3.sceneManager.updateScene(updatedValue);
      });
    }
  }

  function updateEmCubes(emCubes, propMap) {
    emCubes.forEach(function (emCube) {
      return emCube.updateRates(propMap);
    });
  }

  function bustEmCubes(emCubes) {
    emCubes.forEach(function (emCube) {
      return emCube.maybeBust();
    });
  }

  function blowUpEmTowers(squares, sceneManager) {
    squares.filter(function (square) {
      return square.emCubesBusted.length >= NUM_BUSTED_EM_TOWERS_FOR_EXPLOSION;
    }).forEach(function (square) {
      return sceneManager.blowUpEmTower(square);
    });
  }

  function windowDimensions() {
    return {
      width: window.innerWidth,
      height: window.innerHeight,
      dpr: window.devicePixelRatio
    };
  }
});