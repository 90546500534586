define('em-city/game/ec-stars', ['exports', 'em-city/game/constants'], function (exports, _constants) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  var NUMBER_OF_STARS = 1000;
  var NUMBER_OF_TWINKLING_STARS = Math.floor(NUMBER_OF_STARS / 1.5);
  var STAR_RANGE = 50;
  var SIZE = 0.25;
  var COLOR = 0x7CAEFF;
  var TWINKLED_COLOR = 0xE4EEFF;
  var PERCENT_CHANCE_OF_A_STAR_NOT_TWINKLING = 0.96;
  var Y_OFFSET = -4;

  var ECStars = function ECStars() {
    var geometry = new THREE.Geometry();
    geometry.colors = [];

    for (var i = 0; i < NUMBER_OF_STARS; i++) {
      var star = new THREE.Vector3();
      star.x = THREE.Math.randFloatSpread(STAR_RANGE);
      star.y = THREE.Math.randFloatSpread(STAR_RANGE) + Y_OFFSET;
      star.z = THREE.Math.randFloatSpread(STAR_RANGE);
      geometry.vertices.push(star);
      geometry.colors.push(new THREE.Color(COLOR));
    }

    var material = new THREE.PointsMaterial({
      size: SIZE,
      transparent: true,
      vertexColors: THREE.VertexColors,
      map: new THREE.TextureLoader().load(_constants.TEXTURE_PATH_STAR),
      depthWrite: false,
      sizeAttenuation: true
    });

    THREE.Points.call(this, geometry, material);
    this.name = 'stars';
    this.renderOrder = 1;
    this.twinklingStarIndices = [];
    configureTwinklingStars(this.twinklingStarIndices, this.geometry);
  };

  ECStars.prototype = Object.create(THREE.Points.prototype);
  ECStars.prototype.constructor = ECStars;
  ECStars.prototype.adjustOpacity = adjustOpacity;
  ECStars.prototype.animate = animate;
  ECStars.prototype.twinkleStars = twinkleStars;

  function animate() {
    this.twinkleStars();
  }

  function configureTwinklingStars(indices) {
    for (var i = 0; i < NUMBER_OF_TWINKLING_STARS; i++) {
      var starIndex = THREE.Math.randInt(0, NUMBER_OF_STARS - 1);
      indices.push(starIndex);
    }
  }

  function twinkleStars() {
    var _this = this;

    this.twinklingStarIndices.forEach(function (index) {
      if (Math.random() > PERCENT_CHANCE_OF_A_STAR_NOT_TWINKLING) {
        twinkleStarWithIndex(_this.geometry, index);
      }
    });
  }

  function twinkleStarWithIndex(geometry, index) {
    geometry.colorsNeedUpdate = true;
    var color = geometry.colors[index];
    if (color.getHex() === COLOR) {
      color.setHex(TWINKLED_COLOR);
    } else {
      color.setHex(COLOR);
    }
  }

  function adjustOpacity(opacity) {
    opacity = Math.max(opacity, 0);
    this.material.opacity = opacity;
  }

  exports.default = ECStars;
});