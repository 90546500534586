define('em-city/routes/application', ['exports', 'em-city/game/debug-functions', 'em-city/game/constants'], function (exports, _debugFunctions, _constants) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend({
    game: Ember.inject.service(),
    won: Ember.computed.alias('game.won'),
    ranOutOfTime: Ember.computed.alias('game.ranOutOfTime'),
    gameConfigured: Ember.computed(function () {
      var _this = this;

      var gameConfigured = 'game.' + _constants.INITIAL_GAME_CONFIGURATION_COMPLETE;

      return new Ember.RSVP.Promise(function (resolve) {
        if (_this.get(gameConfigured)) resolve();

        _this.addObserver(gameConfigured, function () {
          if (this.get(gameConfigured)) resolve();
        });
      });
    }).volatile(),

    beforeModel: function beforeModel(transition) {
      var _this2 = this;

      this.addObserver('game.won', function () {
        return _this2.goToTheSingularity();
      });
      this.addObserver('game.ranOutOfTime', function () {
        return _this2.createCopy();
      });

      this.get('game').prepareGame();
      return this.get('gameConfigured').then(function () {
        return _this2.debug(transition.queryParams);
      });
    },
    afterModel: function afterModel() {
      this.createCopy();
      this.goToTheSingularity();
    },
    goToTheSingularity: function goToTheSingularity() {
      if (!this.get('won')) return;
      this.transitionTo('the-singularity');
    },
    createCopy: function createCopy() {
      if (!this.get('ranOutOfTime') || this.get('won')) return;
      this.transitionTo('play.create-copy');
    },
    redirect: function redirect(_, transition) {
      var win = transition.queryParams.win;
      if (win) this.transitionTo('play.index');
    },
    debug: function debug(queryParams) {
      var game = this.get('game');
      if (queryParams.win) (0, _debugFunctions.debugWin)(game);
      if (queryParams.stats) (0, _debugFunctions.debugAddStats)(game);
      if (queryParams.empty) (0, _debugFunctions.debugEmpty)(game);
    }
  });
});