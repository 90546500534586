define('em-city/game/ec-ground-square', ['exports', 'em-city/game/ec-em-cube', 'em-city/game/ec-building', 'em-city/game/ec-cooling-unit', 'em-city/game/ec-security-unit', 'em-city/game/ec-smoke', 'em-city/game/ec-cube-tower', 'em-city/game/util', 'em-city/game/constants'], function (exports, _ecEmCube, _ecBuilding, _ecCoolingUnit, _ecSecurityUnit, _ecSmoke, _ecCubeTower, _util, _constants) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var MAX_EMS = 10;

  var ECGroundSquare = function ECGroundSquare(row, column) {
    var width = _constants.GROUND_SQUARE_WIDTH;
    var height = _constants.GROUND_SQUARE_HEIGHT;
    var geometry = new THREE.BoxGeometry(width, height, width);
    var material = new THREE.MeshPhongMaterial({
      color: _constants.GROUND_COLOR,
      transparent: false
    });
    THREE.Mesh.call(this, geometry, material);

    this.row = row;
    this.column = column;
    this.sectorName = 'Sector ' + (row + 1) + ', ' + (column + 1);

    this.building = new _ecBuilding.default();
    this.building.name = 'building-blank';
    this.name = 'ground-square';
    this.ground = null;
    this.touchable = true;
  };

  ECGroundSquare.prototype = Object.create(THREE.Mesh.prototype);
  ECGroundSquare.prototype.constructor = ECGroundSquare;
  ECGroundSquare.prototype.animate = animate;
  ECGroundSquare.prototype.addEm = addEm;
  ECGroundSquare.prototype.removeEm = removeEm;
  ECGroundSquare.prototype.removeEmCubes = removeEmCubes;
  ECGroundSquare.prototype.removeBuilding = removeBuilding;
  ECGroundSquare.prototype.addBuilding = addBuilding;
  ECGroundSquare.prototype.replaceBuilding = replaceBuilding;
  ECGroundSquare.prototype.highlight = highlight;
  ECGroundSquare.prototype.unhighlight = unhighlight;
  ECGroundSquare.prototype.addUnitWithUnitClass = addUnitWithUnitClass;
  ECGroundSquare.prototype.addCoolingUnit = addCoolingUnit;
  ECGroundSquare.prototype.addSecurityUnit = addSecurityUnit;
  ECGroundSquare.prototype.addSmoke = addSmoke;
  ECGroundSquare.prototype.deserializeAndAddUnit = deserializeAndAddUnit;
  ECGroundSquare.prototype.removeSmoke = removeSmoke;
  ECGroundSquare.prototype.removeUnit = removeUnit;
  ECGroundSquare.prototype.removeCoolingUnit = removeCoolingUnit;
  ECGroundSquare.prototype.removeSecurityUnit = removeSecurityUnit;
  ECGroundSquare.prototype.clone = clone;
  ECGroundSquare.prototype.deserializeAndAddEm = deserializeAndAddEm;

  Object.defineProperty(ECGroundSquare.prototype, 'canAddAnEmCube', { get: canAddAnEmCube });

  Object.defineProperty(ECGroundSquare.prototype, 'topEmCube', { get: topEmCube });

  Object.defineProperty(ECGroundSquare.prototype, 'canRemoveTopEmCube', { get: canRemoveTopEmCube });

  Object.defineProperty(ECGroundSquare.prototype, 'emWithSpaceForAUnit', { get: emWithSpaceForAUnit });

  Object.defineProperty(ECGroundSquare.prototype, 'units', { get: units });

  Object.defineProperty(ECGroundSquare.prototype, 'coolingUnits', { get: coolingUnits });

  Object.defineProperty(ECGroundSquare.prototype, 'securityUnits', { get: securityUnits });

  Object.defineProperty(ECGroundSquare.prototype, 'emCubes', { get: emCubes });

  Object.defineProperty(ECGroundSquare.prototype, 'animatables', { get: _util.animatables });

  Object.defineProperty(ECGroundSquare.prototype, 'emCubesOnFire', { get: emCubesOnFire });

  Object.defineProperty(ECGroundSquare.prototype, 'emCubesSabotaged', { get: emCubesSabotaged });

  Object.defineProperty(ECGroundSquare.prototype, 'emCubesBusted', { get: emCubesBusted });

  Object.defineProperty(ECGroundSquare.prototype, 'emCubeTower', { get: emCubeTower });

  Object.defineProperty(ECGroundSquare.prototype, 'serialized', { get: serialized });

  exports.default = ECGroundSquare;


  function animate(timestamp, camera) {
    (0, _util.animateChildren)(this, timestamp, camera);
  }

  function addSmoke(timestamp) {
    var options = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};

    var smoke = new _ecSmoke.default(timestamp, options);
    this.add(smoke);
  }

  function removeSmoke(smoke) {
    this.animatables.splice(this.animatables.indexOf(smoke), 1);
    this.remove(smoke);
  }

  function addBuilding(building) {
    this.building = building;
    building.position.setY(_constants.GROUND_SQUARE_HEIGHT / 2.0);
    building.addToSquare(this);
  }

  function removeBuilding() {
    this.building.removeFromSquare();
  }

  function replaceBuilding(building) {
    this.removeBuilding();
    this.addBuilding(building);
  }

  function addEm() {
    var em = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : new _ecEmCube.default();

    em.createEffects();
    if (!this.canAddAnEmCube) return false;

    var y = this.emCubes.length * em.height;
    em.square = this;
    this.emCubes.push(em);
    this.add(em);
    em.position.setY(y + _constants.GROUND_SQUARE_HEIGHT / 2.0 + em.height / 2.0);
    return true;
  }

  function deserializeAndAddEm(serializedEm) {
    var em = new _ecEmCube.default();
    (0, _util.deserializeProperties)(em, serializedEm);
    this.addEm(em);
  }

  function removeEmCubes() {
    var _this = this;

    this.emCubes.forEach(function (cube) {
      return _this.removeEm(cube);
    });
  }

  function removeEm(emToRemove) {
    if (!emToRemove) return false;
    emToRemove.square = null;
    this.remove(emToRemove);
    return true;
  }

  function addCoolingUnit() {
    return addUnitIfPossible(this, _ecCoolingUnit.default);
  }

  function removeCoolingUnit() {
    var unit = this.coolingUnits[this.coolingUnits.length - 1];
    return this.removeUnit(unit);
  }

  function addSecurityUnit() {
    return addUnitIfPossible(this, _ecSecurityUnit.default);
  }

  function removeSecurityUnit() {
    var unit = this.securityUnits[this.securityUnits.length - 1];
    return this.removeUnit(unit);
  }

  function addUnitWithUnitClass(UnitClass) {
    addUnitIfPossible(this, UnitClass);
  }

  function addUnitIfPossible(square, UnitClass) {
    var em = square.emWithSpaceForAUnit;
    if (!em) return false;

    var index = em.availableUnitIndex;
    em.addUnit(new UnitClass(index));

    return true;
  }

  function deserializeAndAddUnit(serializedUnit) {
    var unitClassMap = { 'ECCoolingUnit': _ecCoolingUnit.default, 'ECSecurityUnit': _ecSecurityUnit.default };
    var em = this.emWithSpaceForAUnit;
    var index = em.availableUnitIndex;
    var unit = new unitClassMap[serializedUnit.klass](index);
    unit.value = serializedUnit.value;
    em.addUnit(unit);
  }

  function removeUnit(unit) {
    if (!unit) return false;
    var em = unit.em;
    em.removeUnit(unit);
    return true;
  }

  function highlight() {
    this.material.color.setHex(_constants.HIGHLIGHT_COLOR);
  }

  function unhighlight() {
    this.material.color.setHex(_constants.GROUND_COLOR);
  }

  function canAddAnEmCube() {
    return this.building.removed && this.emCubes.length < MAX_EMS;
  }

  function topEmCube() {
    return this.emCubes[this.emCubes.length - 1];
  }

  function canRemoveTopEmCube() {
    var em = this.topEmCube;
    return em && em.units.length === 0;
  }

  function emWithSpaceForAUnit() {
    return this.emCubes.find(function (em) {
      return em.availableUnitIndex !== undefined;
    });
  }

  function emCubes() {
    return this.children.filter(function (child) {
      return child.name === 'em-cube';
    });
  }

  function emCubesOnFire() {
    return this.emCubes.filter(function (cube) {
      return cube.overheated;
    });
  }

  function emCubesSabotaged() {
    return this.emCubes.filter(function (cube) {
      return cube.sabotaged;
    });
  }

  function emCubesBusted() {
    return this.emCubes.filter(function (cube) {
      return cube.busted;
    });
  }

  function units() {
    return (0, _util.flatten)(this.emCubes.map(function (em) {
      return em.units;
    }));
  }

  function coolingUnits() {
    var units = this.units.filter(function (unit) {
      return unit.name === 'cooling-unit';
    });
    return units;
  }

  function securityUnits() {
    return this.units.filter(function (unit) {
      return unit.name === 'security-unit';
    });
  }

  function clone() {
    return new this.constructor(this.row, this.column).copy(this);
  }

  function emCubeTower() {
    return new _ecCubeTower.default(this.emCubes, this.position);
  }

  function serialized() {
    var building = this.building.serialized;

    return {
      row: this.row,
      column: this.column,
      building: building,
      cubes: this.emCubes.map(function (cube) {
        return cube.serialized;
      })
    };
  }
});