define('em-city/game/grid-configurer', ['exports', 'em-city/game/ec-ground-square', 'em-city/game/constants'], function (exports, _ecGroundSquare, _constants) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.configureGrid = undefined;


  /*
   * Grid layout:
   * | = columnRoad
   * ----- = rowRoad
   * [] = square
   *
   * []|[]|[]
   * --------
   * []|[]|[]
   * --------
   * []|[]|[]
   */
  function configureGrid() {
    for (var i = 0; i < _constants.ROWS * _constants.ROWS; i++) {
      addRoads(this, i);
      addGroundSquare(this, i);
    }
  }

  function addRoads(context, index) {
    addRowRoad(context, index);
    addColumnRoad(context, index);
  }

  function addGroundSquare(context, index) {
    var row = rowIndexForSquareIndex(index);
    var column = columnIndexForSquareIndex(index);

    var square = new _ecGroundSquare.default(row, column);
    square.ground = context;
    var position = squarePositionForIndex(index);
    square.position.set(position.x, position.y, position.z);
    context.squares.push(square);
    context.add(square);
    square.position.x -= _constants.GROUND_WIDTH / 2.0 - _constants.GROUND_SQUARE_WIDTH / 2.0;
    square.position.z -= _constants.GROUND_WIDTH / 2.0 - _constants.GROUND_SQUARE_WIDTH / 2.0;
  }

  function addRowRoad(context, index) {
    if (isTopRow(index) || !isFirstSquareInRow(index)) return;
    var road = rowRoad();
    var position = rowRoadPositionForIndex(index);
    road.position.x = position.x - _constants.GROUND_WIDTH / 2.0;
    road.position.y = position.y;
    road.position.z = position.z - _constants.GROUND_WIDTH / 2.0;
    context.add(road);
  }

  function addColumnRoad(context, index) {
    if (isFirstSquareInRow(index)) return;
    var road = columnRoad();
    var position = columnRoadPositionForIndex(index);

    road.position.x = position.x - _constants.GROUND_WIDTH / 2.0;
    road.position.y = position.y;
    road.position.z = position.z - _constants.GROUND_WIDTH / 2.0;

    context.add(road);
  }

  function rowRoad() {
    var geometry = new THREE.BoxGeometry(_constants.GROUND_WIDTH, _constants.GROUND_SQUARE_HEIGHT, _constants.ROAD_WIDTH);
    geometry.translate(_constants.GROUND_WIDTH / 2.0, 0, _constants.ROAD_WIDTH / 2.0);
    var material = new THREE.MeshPhongMaterial({ vertexColors: THREE.FaceColors });
    var mesh = new THREE.Mesh(geometry, material);
    mesh.receiveShadow = true;
    configureRoadColors(geometry);
    return mesh;
  }

  function columnRoad() {
    var geometry = new THREE.BoxGeometry(_constants.ROAD_WIDTH, _constants.GROUND_SQUARE_HEIGHT, _constants.GROUND_SQUARE_WIDTH);
    geometry.translate(_constants.ROAD_WIDTH / 2.0, 0, _constants.GROUND_SQUARE_WIDTH / 2.0);
    var material = new THREE.MeshPhongMaterial({ vertexColors: THREE.FaceColors });
    var mesh = new THREE.Mesh(geometry, material);
    mesh.receiveShadow = true;
    configureRoadColors(geometry);
    return mesh;
  }

  function configureRoadColors(geometry) {
    geometry.faces.forEach(function (face) {
      face.color.setHex(_constants.GROUND_COLOR);
      if (face.normal.y === 1) face.color.setHex(_constants.ROAD_COLOR);
    });
  }

  function isTopRow(index) {
    return !parseInt(index / _constants.ROWS);
  }

  function isFirstSquareInRow(index) {
    return !(index % _constants.ROWS);
  }

  function rowIndexForSquareIndex(index) {
    return parseInt(index / _constants.ROWS);
  }

  function columnIndexForSquareIndex(index) {
    return index % _constants.ROWS;
  }

  function columnRoadXPositionForColumnIndex(columnIndex) {
    return columnIndex * _constants.GROUND_SQUARE_WIDTH + (columnIndex - 1) * _constants.ROAD_WIDTH;
  }

  function columnRoadZPositionForRowIndex(rowIndex) {
    return rowIndex * _constants.GROUND_SQUARE_WIDTH + rowIndex * _constants.ROAD_WIDTH;
  }

  function rowRoadZPositionForRowIndex(rowIndex) {
    return rowIndex * _constants.GROUND_SQUARE_WIDTH + (rowIndex - 1) * _constants.ROAD_WIDTH;
  }

  function squareXPositionForIndex(index) {
    var columnIndex = columnIndexForSquareIndex(index);
    return columnIndex * _constants.GROUND_SQUARE_WIDTH + columnIndex * _constants.ROAD_WIDTH;
  }

  function squareZPositionForIndex(index) {
    var rowIndex = rowIndexForSquareIndex(index);
    return rowIndex * _constants.GROUND_SQUARE_WIDTH + rowIndex * _constants.ROAD_WIDTH;
  }

  function columnRoadPositionForIndex(index) {
    var columnIndex = columnIndexForSquareIndex(index);
    var rowIndex = rowIndexForSquareIndex(index);
    var x = columnRoadXPositionForColumnIndex(columnIndex);
    var z = columnRoadZPositionForRowIndex(rowIndex);
    return new THREE.Vector3(x, 0, z);
  }

  function rowRoadPositionForIndex(index) {
    var rowIndex = rowIndexForSquareIndex(index);
    var z = rowRoadZPositionForRowIndex(rowIndex);
    return new THREE.Vector3(0, 0, z);
  }

  function squarePositionForIndex(index) {
    var x = squareXPositionForIndex(index);
    var y = 0;
    var z = squareZPositionForIndex(index);

    return new THREE.Vector3(x, y, z);
  }

  exports.configureGrid = configureGrid;
});