define('em-city/game/ec-cooling-unit', ['exports', 'em-city/game/ec-unit'], function (exports, _ecUnit) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var MAX_COOLING = 4;
  var MIN_COOLING = 2;

  var COLOR_1 = 0x7AADFF;
  var COLOR_2 = 0xFFFFFF;

  var ECCoolingUnit = function ECCoolingUnit(orientationIndex) {
    _ecUnit.default.call(this, orientationIndex);
    this.name = 'cooling-unit';
    this.value = THREE.Math.randInt(MIN_COOLING, MAX_COOLING);
  };

  ECCoolingUnit.prototype = Object.create(_ecUnit.default.prototype);
  ECCoolingUnit.prototype.constructor = ECCoolingUnit;
  ECCoolingUnit.prototype.COLOR_1 = COLOR_1;
  ECCoolingUnit.prototype.COLOR_2 = COLOR_2;

  exports.default = ECCoolingUnit;
});