define('em-city/game/constants', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var ROWS = 3;

  var EM_CUBE_WIDTH = 1.0;
  var ROAD_WIDTH = EM_CUBE_WIDTH / 4.0;
  var GROUND_SQUARE_WIDTH = 2.0 * EM_CUBE_WIDTH;
  var GROUND_SQUARE_HEIGHT = ROAD_WIDTH;
  var GROUND_WIDTH = ROWS * GROUND_SQUARE_WIDTH + (ROWS - 1) * ROAD_WIDTH;

  var UNIT_WIDTH = EM_CUBE_WIDTH;
  var UNIT_DEPTH = ROAD_WIDTH;

  var HIGHLIGHT_COLOR = 0xE0ECFF;
  var GROUND_COLOR = 0xB55475;
  var BUILDING_COLOR = 0x674E8E;
  var ROAD_COLOR = 0xDD2222;

  var MS_PER_UPDATE = 1.0 / 60.0 * 1000.0;

  var ANIMATION_DURATION = 1000;

  var MIN_ZOOM = 20.0;
  var MAX_ZOOM = 100.0;

  var WINNING_PRODUCTION_RATE = 100;
  var ERA_MS = 1000 * 60 * 60 * 24 * 3;

  var TEXTURE_PATH_CLOUD = '/textures/cloud.png';
  var TEXTURE_PATH_STAR = '/textures/star.png';

  var INITIAL_GAME_CONFIGURATION_COMPLETE = 'INITIAL_GAME_CONFIGURATION_COMPLETE';

  exports.ROWS = ROWS;
  exports.EM_CUBE_WIDTH = EM_CUBE_WIDTH;
  exports.ROAD_WIDTH = ROAD_WIDTH;
  exports.GROUND_WIDTH = GROUND_WIDTH;
  exports.GROUND_SQUARE_WIDTH = GROUND_SQUARE_WIDTH;
  exports.GROUND_SQUARE_HEIGHT = GROUND_SQUARE_HEIGHT;
  exports.UNIT_WIDTH = UNIT_WIDTH;
  exports.UNIT_DEPTH = UNIT_DEPTH;
  exports.HIGHLIGHT_COLOR = HIGHLIGHT_COLOR;
  exports.GROUND_COLOR = GROUND_COLOR;
  exports.BUILDING_COLOR = BUILDING_COLOR;
  exports.ROAD_COLOR = ROAD_COLOR;
  exports.MS_PER_UPDATE = MS_PER_UPDATE;
  exports.ANIMATION_DURATION = ANIMATION_DURATION;
  exports.MIN_ZOOM = MIN_ZOOM;
  exports.MAX_ZOOM = MAX_ZOOM;
  exports.WINNING_PRODUCTION_RATE = WINNING_PRODUCTION_RATE;
  exports.ERA_MS = ERA_MS;
  exports.TEXTURE_PATH_CLOUD = TEXTURE_PATH_CLOUD;
  exports.TEXTURE_PATH_STAR = TEXTURE_PATH_STAR;
  exports.INITIAL_GAME_CONFIGURATION_COMPLETE = INITIAL_GAME_CONFIGURATION_COMPLETE;
});