define('em-city/components/time-left', ['exports', 'em-city/util/animate-value-task', 'em-city/game/constants'], function (exports, _animateValueTask, _constants) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    classNames: ['buttons', 'buttons-bottom'],
    formattedTimeLeft: Ember.computed('displayTimeLeft', function () {
      return formattedTime(this.get('displayTimeLeft'));
    }),
    color: Ember.computed('formattedTimeLeft', function () {
      var timeLeft = this.get('displayTimeLeft');
      var notRed = parseInt(timeLeft / _constants.ERA_MS * 255);
      return Ember.String.htmlSafe('color: rgb(255, ' + notRed + ', ' + notRed + ')');
    }),
    animateValueTask: _animateValueTask.default,
    displayTimeLeft: 0,

    didReceiveAttrs: function didReceiveAttrs() {
      var _this = this;

      var timeLeft = this.get('timeLeft');
      var displayTimeLeft = this.get('displayTimeLeft');
      if (timeLeft === displayTimeLeft) return;
      this.get('animateValueTask').perform(displayTimeLeft, timeLeft, function (updatedValue) {
        var value = Math.max(0, Math.round(updatedValue));
        _this.set('displayTimeLeft', value);
      });
    }
  });


  function formattedTime(timeInMs) {
    var prettyTime = timeInMs.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
    return prettyTime + ' ms';
  }
});