define("em-city/game/game-loop", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  exports.default = function (frameRate, gameLogicFunction, context) {
    var lag = 0.0;
    var previousTimestamp = 0.0;

    return function (timestamp) {
      lag += timestamp - previousTimestamp;
      previousTimestamp = timestamp;

      while (lag >= frameRate) {
        gameLogicFunction.call(context, timestamp);
        lag -= frameRate;
      }
    };
  };
});