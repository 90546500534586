define('em-city/game/ec-cube-tower', ['exports', 'em-city/game/ec-rocket-trail'], function (exports, _ecRocketTrail) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var ECCubeTower = function ECCubeTower(cubes, position) {
    var _this = this;

    THREE.Group.call(this);
    this.name = 'cube-tower';

    cubes.forEach(function (cube) {
      cube.unbust();
      _this.add(cube);
    });

    this.add(new _ecRocketTrail.default(0));
    this.position.set(position.x, position.y, position.z);
  };

  ECCubeTower.prototype = Object.create(THREE.Group.prototype);
  ECCubeTower.prototype.constructor = ECCubeTower;
  ECCubeTower.prototype.animate = animate;

  function animate(timestamp, camera) {
    this.children.forEach(function (child) {
      return child.animate(timestamp, camera);
    });
  }

  exports.default = ECCubeTower;
});