define('em-city/game/ec-smoke', ['exports', 'em-city/ember-stringify', 'em-city/game/constants'], function (exports, _emberStringify, _constants) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var NUM_PARTICLES = 1500;
  var MIN_SPEED = 0.0;
  var MAX_SPEED = 4.0;
  var LIFESPAN = 2000;
  var DEFAULT_COLOR = new THREE.Color(0xFFFFFF);
  var FINAL_SMOKE_SIZE = 1.0;
  var INITIAL_SMOKE_SIZE = 0.10;
  var SIZE_VARIABILITY = 0.25;
  var SATURATION = 1.0;
  var LIGHTNESS = 0.75;
  var RADIUS = 1.3;

  var BASE_UNIFORMS = {
    uTime: { value: 0.0 },
    uLifespan: { value: LIFESPAN },
    uInitialSmokeSize: { value: INITIAL_SMOKE_SIZE },
    uZoom: { value: 1.0 }
  };

  var ECSmoke = function ECSmoke(startTime) {
    var options = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};

    options.color = options.color || DEFAULT_COLOR;
    options.blending = options.blending || THREE.AdditiveBlending;

    var uniforms = {
      uColor: { value: options.color },
      uStartTime: { value: startTime },
      uTexture: { value: new THREE.TextureLoader().load(_constants.TEXTURE_PATH_CLOUD) }
    };

    Object.assign(uniforms, BASE_UNIFORMS);

    var shaderMaterial = new THREE.ShaderMaterial({
      uniforms: uniforms,
      vertexShader: _emberStringify.default['smoke-vertex.glsl'],
      fragmentShader: _emberStringify.default['smoke-fragment.glsl'],
      blending: options.blending,
      depthWrite: false,
      transparent: true
    });

    THREE.Points.call(this, geometryFromRadius(RADIUS), shaderMaterial);
    this.name = 'smoke';
    this.removeWhenDead();
    this.renderOrder = 2;
  };

  ECSmoke.prototype.constructor = ECSmoke;
  ECSmoke.prototype = Object.create(THREE.Points.prototype);
  ECSmoke.prototype.animate = animate;
  ECSmoke.prototype.removeWhenDead = removeWhenDead;

  function animate(timestamp, camera) {
    this.material.uniforms.uTime.value = timestamp;
    this.material.uniforms.uZoom.value = camera.zoom;
  }

  function removeWhenDead() {
    var _this = this;

    setTimeout(function () {
      _this.parent.removeSmoke(_this);
    }, LIFESPAN);
  }

  // https://threejs.org/examples/webgl_buffergeometry_custom_attributes_particles.html
  function geometryFromRadius(radius) {
    var geometry = new THREE.BufferGeometry();

    var positions = new Float32Array(NUM_PARTICLES * 3);
    var velocities = new Float32Array(NUM_PARTICLES * 3);
    var colors = new Float32Array(NUM_PARTICLES * 3);
    var sizes = new Float32Array(NUM_PARTICLES);
    var startingAges = new Float32Array(NUM_PARTICLES);

    var color = new THREE.Color();

    for (var i = 0, i3 = 0; i < NUM_PARTICLES; i++, i3 += 3) {
      positions[i3 + 0] = THREE.Math.randFloat(-radius, radius);
      positions[i3 + 1] = THREE.Math.randFloat(0, radius);
      positions[i3 + 2] = THREE.Math.randFloat(-radius, radius);

      velocities[i3 + 0] = THREE.Math.randFloat(MIN_SPEED, MAX_SPEED);
      velocities[i3 + 1] = THREE.Math.randFloat(MIN_SPEED, MAX_SPEED);
      velocities[i3 + 2] = THREE.Math.randFloat(MIN_SPEED, MAX_SPEED);

      color.setHSL(i / NUM_PARTICLES, SATURATION, LIGHTNESS);

      colors[i3 + 0] = color.r;
      colors[i3 + 1] = color.g;
      colors[i3 + 2] = color.b;

      sizes[i] = FINAL_SMOKE_SIZE + THREE.Math.randFloat(0, SIZE_VARIABILITY);
      startingAges[i] = THREE.Math.randFloat(0, 1.0);
    }

    geometry.addAttribute('position', new THREE.BufferAttribute(positions, 3));
    geometry.addAttribute('velocity', new THREE.BufferAttribute(velocities, 3));
    geometry.addAttribute('color', new THREE.BufferAttribute(colors, 3));
    geometry.addAttribute('size', new THREE.BufferAttribute(sizes, 1));
    geometry.addAttribute('startingAge', new THREE.BufferAttribute(startingAges, 1));

    return geometry;
  }

  exports.default = ECSmoke;
});