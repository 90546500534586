define('em-city/game/ec-unit', ['exports', 'em-city/game/ec-unit-shader', 'em-city/game/constants'], function (exports, _ecUnitShader, _constants) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var ECUnit = function ECUnit(orientationIndex) {
    var geometry = new THREE.BoxGeometry(_constants.UNIT_WIDTH, _constants.UNIT_WIDTH, _constants.UNIT_DEPTH);
    var material = new _ecUnitShader.default();

    THREE.Mesh.call(this, geometry, material);

    material.uniforms.uColor1.value.setHex(this.COLOR_1);
    material.uniforms.uColor2.value.setHex(this.COLOR_2);
    this.castShadow = false;
    this.receiveShadow = false;
    this.name = 'unit';
    this.em = null;
    this.square = null;
    this.touchable = true;
    this.highlighted = false;

    this.orientationIndex = orientationIndex;
    this.configurePosition();
  };

  ECUnit.prototype = Object.create(THREE.Mesh.prototype);
  ECUnit.prototype.constructor = ECUnit;
  ECUnit.prototype.highlight = highlight;
  ECUnit.prototype.unhighlight = unhighlight;
  ECUnit.prototype.configurePosition = configurePosition;
  ECUnit.prototype.COLOR = 0xFFF000;
  ECUnit.prototype.clone = clone;
  ECUnit.prototype.animate = animate;

  Object.defineProperty(ECUnit.prototype, 'serialized', { get: serialized });

  exports.default = ECUnit;


  function animate(timestamp) {
    this.material.uniforms.uTime.value = timestamp;
    this.material.uniforms.opacity.value = this.material.opacity;
  }

  function configurePosition() {
    var _orientationForIndex = orientationForIndex(this.orientationIndex),
        position = _orientationForIndex.position,
        rotation = _orientationForIndex.rotation;

    this.rotateY(rotation);
    this.position.set(position.x, position.y, position.z);
  }

  function highlight() {
    this.highlighted = true;
    this.material.color.setHex(_constants.HIGHLIGHT_COLOR);
  }

  function unhighlight() {
    this.highlighted = false;
  }

  function orientationForIndex(index) {
    var distanceFromCenter = _constants.UNIT_WIDTH / 2.0 + _constants.UNIT_DEPTH / 2.0;

    return [{
      position: new THREE.Vector3(0, 0, -distanceFromCenter),
      rotation: 0
    }, {
      position: new THREE.Vector3(distanceFromCenter, 0, 0),
      rotation: Math.PI / 2.0
    }, {
      position: new THREE.Vector3(0, 0, distanceFromCenter),
      rotation: Math.PI
    }, {
      position: new THREE.Vector3(-distanceFromCenter, 0, 0),
      rotation: -Math.PI / 2.0
    }][index];
  }

  function clone() {
    return new this.constructor(this.orientationIndex).copy(this);
  }

  function serialized() {
    return {
      klass: this.constructor.name,
      orientationIndex: this.orientationIndex,
      value: this.value
    };
  }
});