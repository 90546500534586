define('em-city/game/game-actions', ['exports', 'em-city/game/sound-effects', 'em-city/game/game-action-animations'], function (exports, _soundEffects, _gameActionAnimations) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  function _classCallCheck(instance, Constructor) {
    if (!(instance instanceof Constructor)) {
      throw new TypeError("Cannot call a class as a function");
    }
  }

  var _createClass = function () {
    function defineProperties(target, props) {
      for (var i = 0; i < props.length; i++) {
        var descriptor = props[i];
        descriptor.enumerable = descriptor.enumerable || false;
        descriptor.configurable = true;
        if ("value" in descriptor) descriptor.writable = true;
        Object.defineProperty(target, descriptor.key, descriptor);
      }
    }

    return function (Constructor, protoProps, staticProps) {
      if (protoProps) defineProperties(Constructor.prototype, protoProps);
      if (staticProps) defineProperties(Constructor, staticProps);
      return Constructor;
    };
  }();

  var GameActions = function () {
    function GameActions(sceneManager, successCallback) {
      _classCallCheck(this, GameActions);

      this.sceneManager = sceneManager;
      this.successCallback = successCallback;
      this.timestamp = null;
      this.soundEffects = new _soundEffects.default();
    }

    _createClass(GameActions, [{
      key: 'removeBuilding',
      value: function removeBuilding(square) {
        if (square.building.removed) return;
        this.sceneManager.removeBodyFromWorld(square.building.body);
        (0, _gameActionAnimations.animateRemoveBuilding)(square);
        this.addSmokeAndShakeGround(square);
        this.playGreenSoundEffect();
        this.successCallback();
      }
    }, {
      key: 'extinguishFire',
      value: function extinguishFire(square) {
        var cube = square.emCubesOnFire[0];
        if (!cube) return;
        cube.extinguishFire();
        this.addSmokeAndShakeGround(square);
        this.playGreenSoundEffect();
        this.successCallback();
      }
    }, {
      key: 'repairSabotage',
      value: function repairSabotage(square) {
        var cube = square.emCubesSabotaged[0];
        if (!cube) return;
        cube.repairSabotage();
        this.addSmokeAndShakeGround(square);
        this.playGreenSoundEffect();
        this.successCallback();
      }
    }, {
      key: 'addEmCube',
      value: function addEmCube(square) {
        if (!square.canAddAnEmCube) return;
        square.addEm();
        this.addSmokeAndShakeGround(square);
        this.playGreenSoundEffect();
        this.successCallback();
      }
    }, {
      key: 'removeEmCube',
      value: function removeEmCube(square) {
        var em = square.emCubes[square.emCubes.length - 1];
        if (!em) return;
        var removed = em.square.removeEm(em);

        if (removed) this.successCallback();
        this.addSmokeAndShakeGround(square);
        this.playRedSoundEffect();
      }
    }, {
      key: 'addCoolingUnit',
      value: function addCoolingUnit(square) {
        var added = square.addCoolingUnit();
        if (added) this.successCallback();
        this.addSmokeAndShakeGround(square);
        this.playGreenSoundEffect();
      }
    }, {
      key: 'removeCoolingUnit',
      value: function removeCoolingUnit(square) {
        var removed = square.removeCoolingUnit();
        if (removed) this.successCallback();
        this.addSmokeAndShakeGround(square);
        this.playRedSoundEffect();
      }
    }, {
      key: 'addSecurityUnit',
      value: function addSecurityUnit(square) {
        var added = square.addSecurityUnit();
        if (added) this.successCallback();
        this.addSmokeAndShakeGround(square);
        this.playGreenSoundEffect();
      }
    }, {
      key: 'removeSecurityUnit',
      value: function removeSecurityUnit(square) {
        var removed = square.removeSecurityUnit();
        if (removed) this.successCallback();
        this.addSmokeAndShakeGround(square);
        this.playRedSoundEffect();
      }
    }, {
      key: 'addSmokeAndShakeGround',
      value: function addSmokeAndShakeGround(square) {
        square.addSmoke(this.timestamp);
        (0, _gameActionAnimations.animateShakeGround)(square.ground);
      }
    }, {
      key: 'playRedSoundEffect',
      value: function playRedSoundEffect() {
        this.soundEffects.play('remove');
      }
    }, {
      key: 'playGreenSoundEffect',
      value: function playGreenSoundEffect() {
        this.soundEffects.play('add');
      }
    }]);

    return GameActions;
  }();

  exports.default = GameActions;
});