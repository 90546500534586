define('em-city/game/ec-building-shader', ['exports', 'em-city/ember-stringify'], function (exports, _emberStringify) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var UNIFORMS = [THREE.ShaderLib['phong'].uniforms, {
    uLocalMatrix: { value: new THREE.Matrix4() }
  }];

  var SHININESS = 50;
  var SPECULAR_COLOR = 0x111111;

  var ECBuildingShader = function ECBuildingShader() {
    THREE.ShaderMaterial.call(this, {
      uniforms: THREE.UniformsUtils.merge(UNIFORMS),
      vertexShader: _emberStringify.default['phong-vertex.glsl'],
      fragmentShader: _emberStringify.default['building-fragment.glsl']
    });

    this.lights = true;
    this.color = new THREE.Color();
    this.specular = new THREE.Color(SPECULAR_COLOR);
    this.shininess = SHININESS;
    this.transparent = true;

    this.uniforms.diffuse.value = this.color;
    this.uniforms.specular.value = this.specular;
    this.uniforms.shininess.value = this.shininess;
    this.uniforms.opacity.value = this.opacity;
  };

  ECBuildingShader.prototype = Object.create(THREE.ShaderMaterial.prototype);
  ECBuildingShader.prototype.constructor = ECBuildingShader;

  exports.default = ECBuildingShader;
});