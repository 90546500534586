define('em-city/game/debug-functions', ['exports', 'em-city/game/constants', 'em-city/game/property-manager'], function (exports, _constants, _propertyManager) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.debugEmpty = exports.debugAddStats = exports.debugWin = undefined;


  var NUM_CUBES_TO_ADD = 3;

  function debugAddStats(game) {
    var stats = new Stats();
    stats.showPanel(0);
    game.set('stats', stats);
    document.body.appendChild(stats.dom);
  }

  function debugEmpty(game) {
    game.reset();
    debugRemoveBuildings(game, 9);
  }

  function debugRemoveBuildings(game, numberToRemove) {
    var squares = game.sceneManager.ground.squares;
    for (var i = numberToRemove; i > 0; i--) {
      var square = game.sceneManager.ground.squares[squares.length - i];
      square.removeBuilding(square);
    }
  }

  function debugWin(game) {
    game.reset();
    debugRemoveBuildings(game, NUM_CUBES_TO_ADD);
    debugAddEmCubes(game);
    game.propertyManager.updatedProperties = function () {
      var propMap = Object.assign({}, _propertyManager.default.INITIAL_PROPERTY_MAP);
      propMap.production = _constants.WINNING_PRODUCTION_RATE;
      propMap.insurrection = 0;
      propMap.heat = 0;
      propMap.timeLeft = 1;
      return propMap;
    };

    game.completeTurn();
  }

  function debugAddEmCubes(game) {
    var squares = game.sceneManager.ground.squares;
    for (var i = NUM_CUBES_TO_ADD; i > 0; i--) {
      var square = game.sceneManager.ground.squares[squares.length - i];
      for (var j = 0; j < i; j++) {
        square.addEm();
      }

      square.addCoolingUnit();
      square.addSecurityUnit();
    }
  }

  exports.debugWin = debugWin;
  exports.debugAddStats = debugAddStats;
  exports.debugEmpty = debugEmpty;
});