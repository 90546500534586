define('em-city/config/ember-spinner/standard', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = {
    color: '#fff',
    lines: 10,
    length: 10
  };
});