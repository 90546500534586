define('em-city/router', ['exports', 'em-city/config/environment'], function (exports, _environment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var Router = Ember.Router.extend({
    location: _environment.default.locationType,
    rootURL: _environment.default.rootURL,
    metrics: Ember.inject.service(),

    didTransition: function didTransition() {
      this._super.apply(this, arguments);
      this._trackPage();
    },
    _trackPage: function _trackPage() {
      var _this = this;

      Ember.run.scheduleOnce('afterRender', this, function () {
        var page = _this.get('url');
        var title = _this.getWithDefault('currentRouteName', 'unknown');
        Ember.get(_this, 'metrics').trackPage({ page: page, title: title });
      });
    }
  });

  Router.map(function () {
    this.route('play', function () {
      this.route('production');
      this.route('insurrection');
      this.route('heat');
      this.route('instructions');
      this.route('create-copy');
      this.route('sector', { path: 'sector/:sector_id' });
    });
    this.route('the-singularity');
  });

  exports.default = Router;
});