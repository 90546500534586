define('em-city/game/ec-clouds', ['exports', 'em-city/ember-stringify', 'em-city/game/util', 'em-city/game/constants'], function (exports, _emberStringify, _util, _constants) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var NUM_CLOUDS = 8;
  var PUFFS_PER_CLOUD = 10;
  var NUM_PUFFS = NUM_CLOUDS * PUFFS_PER_CLOUD;
  var MIN_PUFF_OFFSET = 0.25;
  var MAX_PUFF_OFFSET = 0.75;
  var MIN_SPEED = 0.5;
  var MAX_SPEED = 2.5;
  var LIFESPAN = 15000;
  var DEFAULT_COLOR = 0xFFFFFF;
  var MIN_SIZE = 2.5;
  var SIZE_VARIABILITY = 0.0005;
  var GROWTH_RATE = 0.001;
  var DEFAULT_RADIUS = 4.0;
  var MAX_OPACITY = 1.0;
  var HEIGHT_VARIANCE = 0.5;
  var CLOUD_HEIGHT = 5.5;

  var BASE_UNIFORMS = {
    uColor: { value: new THREE.Color(DEFAULT_COLOR) },
    uTime: { value: 0.0 },
    uLifespan: { value: LIFESPAN },
    uAnimationSpeed: { value: _constants.MS_PER_UPDATE },
    uMaxOpacity: { value: MAX_OPACITY },
    uZoom: { value: 1.0 },
    uGrowthRate: { value: GROWTH_RATE }
  };

  var ECClouds = function ECClouds() {
    var uniforms = { uTexture: { value: new THREE.TextureLoader().load(_constants.TEXTURE_PATH_CLOUD) } };
    Object.assign(uniforms, BASE_UNIFORMS);

    var shaderMaterial = new THREE.ShaderMaterial({
      uniforms: uniforms,
      vertexShader: _emberStringify.default['clouds-vertex.glsl'],
      fragmentShader: _emberStringify.default['clouds-fragment.glsl'],
      transparent: true,
      depthWrite: false
    });

    THREE.Points.call(this, geometryFromRadius(DEFAULT_RADIUS), shaderMaterial);
    this.translateY(CLOUD_HEIGHT);

    this.renderOrder = 1;
    this.name = 'clouds';
  };

  ECClouds.prototype.constructor = ECClouds;
  ECClouds.prototype = Object.create(THREE.Points.prototype);
  ECClouds.prototype.animate = animate;
  ECClouds.prototype.adjustOpacity = adjustOpacity;

  function animate(timestamp, camera) {
    this.material.uniforms.uTime.value = timestamp;
    this.material.uniforms.uZoom.value = camera.zoom;
  }

  // https://threejs.org/examples/webgl_buffergeometry_custom_attributes_particles.html
  function geometryFromRadius(radius) {
    var geometry = new THREE.BufferGeometry();

    var positions = new Float32Array(NUM_PUFFS * 3);
    var velocities = new Float32Array(NUM_PUFFS * 3);
    var sizes = new Float32Array(NUM_PUFFS);
    var startingAges = new Float32Array(NUM_PUFFS);

    for (var i = 0, i3 = 0; i < NUM_CLOUDS; i++, i3 += 3) {
      var x = (0, _util.randomSign)() * THREE.Math.randFloat(0, radius);
      var y = (0, _util.randomSign)() * THREE.Math.randFloat(0.0, HEIGHT_VARIANCE);
      var z = (0, _util.randomSign)() * THREE.Math.randFloat(0, radius);
      var vel = THREE.Math.randFloat(MIN_SPEED, MAX_SPEED);
      var age = THREE.Math.randFloat(0.0, 1.0);
      for (var j = 0; j < PUFFS_PER_CLOUD; j++) {
        var index = PUFFS_PER_CLOUD * i3 + 3 * j;
        positions[index + 0] = x + (0, _util.randomSign)() * THREE.Math.randFloat(MIN_PUFF_OFFSET, MAX_PUFF_OFFSET);
        positions[index + 1] = y + (0, _util.randomSign)() * THREE.Math.randFloat(MIN_PUFF_OFFSET, MAX_PUFF_OFFSET);
        positions[index + 2] = z + (0, _util.randomSign)() * THREE.Math.randFloat(MIN_PUFF_OFFSET, MAX_PUFF_OFFSET);

        velocities[index + 0] = vel;
        velocities[index + 1] = 0;
        velocities[index + 2] = 0;

        sizes[index] = MIN_SIZE + THREE.Math.randFloat(0, SIZE_VARIABILITY);
        startingAges[index] = age;
      }
    }

    geometry.addAttribute('position', new THREE.BufferAttribute(positions, 3));
    geometry.addAttribute('velocity', new THREE.BufferAttribute(velocities, 3));
    geometry.addAttribute('size', new THREE.BufferAttribute(sizes, 1));
    geometry.addAttribute('startingAge', new THREE.BufferAttribute(startingAges, 1));

    return geometry;
  }

  function adjustOpacity(opacity) {
    this.material.uniforms.uMaxOpacity.value = opacity;
  }

  exports.default = ECClouds;
});