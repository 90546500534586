define('em-city/game/property-manager', ['exports', 'em-city/game/constants'], function (exports, _constants) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  function _classCallCheck(instance, Constructor) {
    if (!(instance instanceof Constructor)) {
      throw new TypeError("Cannot call a class as a function");
    }
  }

  var _createClass = function () {
    function defineProperties(target, props) {
      for (var i = 0; i < props.length; i++) {
        var descriptor = props[i];
        descriptor.enumerable = descriptor.enumerable || false;
        descriptor.configurable = true;
        if ("value" in descriptor) descriptor.writable = true;
        Object.defineProperty(target, descriptor.key, descriptor);
      }
    }

    return function (Constructor, protoProps, staticProps) {
      if (protoProps) defineProperties(Constructor.prototype, protoProps);
      if (staticProps) defineProperties(Constructor, staticProps);
      return Constructor;
    };
  }();

  var YEARS_IN_THE_FUTURE = 20;
  var INITIAL_TURN_SPEED = 0.5;
  var ONE_DAY_IN_MS = 1000 * 60 * 60 * 24;
  var CIRCLE = 2 * Math.PI;
  var INSURRECTION_PRODUCTION_RATIO = 1.0 / 10.0;
  var HEAT_PRODUCTION_RATIO = 1.0 / 5.0;
  var MIN_PRODUCTION_WITH_CUBE = 2;
  var INITIAL_PROPERTY_MAP = Ember.Object.create({
    sceneTime: 0,
    calendarTime: noonTodayPlusYearsInFutureInEpochMs(),
    timeLeft: _constants.ERA_MS,
    production: 0,
    insurrection: 0,
    heat: 0,
    turnSpeed: INITIAL_TURN_SPEED
  });

  var PropertyManager = function () {
    _createClass(PropertyManager, null, [{
      key: 'INITIAL_PROPERTY_MAP',
      get: function get() {
        return INITIAL_PROPERTY_MAP;
      }
    }]);

    function PropertyManager(ground) {
      _classCallCheck(this, PropertyManager);

      this.configure(ground);
    }

    _createClass(PropertyManager, [{
      key: 'configure',
      value: function configure(ground) {
        this.turnSpeed = INITIAL_TURN_SPEED;
        this.ground = ground;
      }
    }, {
      key: 'updatedProperties',
      value: function updatedProperties(currentProps) {
        var updateTime = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : true;

        if (!this.ground) return INITIAL_PROPERTY_MAP;

        var insurrection = updatedInsurrection(this.ground.insurrection, this.ground.squares, this.ground.emCubes, this.ground.securityUnits);

        var production = updatedProduction(this.ground.emCubes, insurrection);

        var heat = updatedHeat(this.ground.heat, this.ground.emCubes, this.ground.coolingUnits);

        var turnSpeed = updatedTurnSpeed(production);
        var calendarTime = currentProps.calendarTime,
            sceneTime = currentProps.sceneTime,
            timeLeft = currentProps.timeLeft;


        if (updateTime) {
          calendarTime = updatedCalendarTime(currentProps.calendarTime, turnSpeed);
          sceneTime = updatedSceneTime(currentProps.sceneTime, turnSpeed);
          timeLeft = updatedTimeLeft(currentProps.timeLeft, turnSpeed);
        }

        return {
          calendarTime: calendarTime,
          sceneTime: sceneTime,
          timeLeft: timeLeft,
          turnSpeed: turnSpeed,
          production: production,
          insurrection: insurrection,
          heat: heat
        };
      }
    }]);

    return PropertyManager;
  }();

  exports.default = PropertyManager;


  function updatedCalendarTime(current, turnSpeed) {
    return current + ONE_DAY_IN_MS * turnSpeed;
  }

  function updatedSceneTime(current, turnSpeed) {
    return current + CIRCLE * turnSpeed;
  }

  function updatedTimeLeft(current, turnSpeed) {
    return current - ONE_DAY_IN_MS * turnSpeed;
  }

  function updatedProduction(cubes, insurrection) {
    var cubeProduction = cubes.reduce(function (total, cube) {
      return total + cube.production;
    }, 0);
    var lossFromInsurrection = insurrection * INSURRECTION_PRODUCTION_RATIO;
    var lossFromHeat = insurrection * HEAT_PRODUCTION_RATIO;

    var currentProduction = cubeProduction - lossFromInsurrection - lossFromHeat;
    var workingCube = cubes.find(function (cube) {
      return !cube.busted;
    });
    if (workingCube) currentProduction = Math.max(currentProduction, MIN_PRODUCTION_WITH_CUBE);

    return formatter(currentProduction);
  }

  function updatedInsurrection(insurrection, squares, cubes, securityUnits) {
    var removedBuildingInsurrection = squares.reduce(function (total, square) {
      var squareInsurrection = 0;

      if (square.building.removed) {
        squareInsurrection = square.building.insurrectionIfRemoved;
      }

      return total + squareInsurrection;
    }, 0);

    var emCubeInsurrection = cubes.reduce(function (total, cube) {
      return total + cube.insurrection;
    }, 0);

    var emCubeSecurity = securityUnits.reduce(function (total, unit) {
      return total + unit.value;
    }, 0);

    return formatter(insurrection + removedBuildingInsurrection + emCubeInsurrection - emCubeSecurity);
  }

  function updatedHeat(heat, cubes, coolingUnits) {
    var emCubeHeat = cubes.reduce(function (total, cube) {
      return total + cube.heat;
    }, 0);

    var emCubeCooling = coolingUnits.reduce(function (total, unit) {
      return total + unit.value;
    }, 0);

    return formatter(heat + emCubeHeat - emCubeCooling);
  }

  function updatedTurnSpeed(production) {
    var turnSpeed = INITIAL_TURN_SPEED;
    if (production !== 0) turnSpeed = 1 / (production + 1);
    return turnSpeed;
  }

  function noonTodayPlusYearsInFutureInEpochMs() {
    var today = new Date();
    today.setFullYear(today.getFullYear() + YEARS_IN_THE_FUTURE);
    today.setHours(12);
    today.setMinutes(0);
    today.setSeconds(0);
    today.setMilliseconds(0);
    return today.getTime();
  }

  function formatter(value) {
    return Math.floor(Math.max(value, 0));
  }
});