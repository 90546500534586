define('em-city/game/ec-scene', ['exports', 'em-city/game/util'], function (exports, _util) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var MIN_BACKGROUND_BRIGHTNESS = 0.1;
  var MAX_BACKGROUND_BRIGHTNESS = 0.74;
  var BACKGROUND_HUE = 217;
  var BACKGROUND_SATURATION = 1.0;
  var SCALE_HEIGHT = 580;
  var SCALE_FACTOR = 30.0;

  var ECScene = function ECScene() {
    THREE.Scene.apply(this);
    this.adjustBackgroundLightness(1.0);
  };

  ECScene.prototype = Object.create(THREE.Scene.prototype);
  ECScene.prototype.constructor = ECScene;
  ECScene.prototype.adjustBackgroundLightness = adjustBackgroundLightness;
  ECScene.prototype.scaleWithDimensions = scaleWithDimensions;
  ECScene.prototype.animate = animate;

  Object.defineProperty(ECScene.prototype, 'animatables', { get: _util.animatables });

  exports.default = ECScene;


  function animate(timestamp, camera) {
    (0, _util.animateChildren)(this, timestamp, camera);
  }

  function backgroundColorForLightness(percentLight) {
    var lightness = THREE.Math.clamp(percentLight, MIN_BACKGROUND_BRIGHTNESS, MAX_BACKGROUND_BRIGHTNESS);
    var lightnessInteger = parseInt(lightness * 100);
    return 'hsl(' + BACKGROUND_HUE + ', ' + BACKGROUND_SATURATION * 100 + '%, ' + lightnessInteger + '%)';
  }

  function adjustBackgroundLightness(percentLight) {
    this.background = new THREE.Color(backgroundColorForLightness(percentLight));
  }

  function scaleWithDimensions(dimensions) {
    var height = dimensions.height;

    var scaleFactor = scaleFactorForHeight(height);
    this.scale.set(scaleFactor, scaleFactor, scaleFactor);
    this.updateMatrixWorld();
  }

  function scaleFactorForHeight(currentHeight) {
    return SCALE_FACTOR / SCALE_HEIGHT * currentHeight;
  }
});