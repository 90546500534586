define('em-city/routes/play', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend({
    game: Ember.inject.service(),
    activate: function activate() {
      this.get('game').turnOffDemoMode();
      this.set('game.onTop', true);
    },
    deactivate: function deactivate() {
      this.get('game').turnOnDemoMode();
      this.set('game.onTop', false);
    }
  });
});