define("em-city/game/ec-camera", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var CAMERA_FRUSTUM_DIVISOR = 2;
  var INITIAL_CAMERA_POSITION = new THREE.Vector3(10, 10, 10);
  var NEAR_PLANE = 2;
  var FAR_PLANE = 1000;

  var ECCamera = function ECCamera() {
    THREE.OrthographicCamera.apply(this, [0, 0, 0, 0, NEAR_PLANE, FAR_PLANE]);

    this.position.set(INITIAL_CAMERA_POSITION.x, INITIAL_CAMERA_POSITION.y, INITIAL_CAMERA_POSITION.z);
  };

  ECCamera.prototype = Object.create(THREE.OrthographicCamera.prototype);
  ECCamera.prototype.constructor = ECCamera;
  ECCamera.prototype.updateWithScaledDimensions = updateWithScaledDimensions;

  exports.default = ECCamera;


  function updateWithScaledDimensions(dimensions, scaleFactor) {
    var frustum = frustumFromDimensions(dimensions);

    this.near = NEAR_PLANE;
    this.far = FAR_PLANE * scaleFactor;
    this.aspect = dimensions.width / dimensions.height;
    this.left = frustum.left;
    this.right = frustum.right;
    this.top = frustum.top;
    this.bottom = frustum.bottom;
    this.zoom = scaleFactor;
    this.updateProjectionMatrix();
  }

  function frustumFromDimensions(dimensions) {
    var width = dimensions.width;
    var height = dimensions.height;

    return {
      left: -width / CAMERA_FRUSTUM_DIVISOR,
      right: width / CAMERA_FRUSTUM_DIVISOR,
      top: height / CAMERA_FRUSTUM_DIVISOR,
      bottom: -height / CAMERA_FRUSTUM_DIVISOR
    };
  }
});