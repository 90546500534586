define('em-city/controllers/play/instructions', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend({
    game: Ember.inject.service(),
    name: Ember.computed.alias('game.name')
  });
});