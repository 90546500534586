define('em-city/game/ec-glow-mesh', ['exports', 'em-city/ember-stringify'], function (exports, _emberStringify) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var DEFAULT_P = 0.0;
  var DEFAULT_SCALE = 1.5;

  var ECGlowMesh = function ECGlowMesh(geometry, color) {
    var propBag = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : {};

    this.color = color;
    this.propBag = propBag;

    var shaderMaterial = new THREE.ShaderMaterial({
      uniforms: {
        'p': { type: 'f', value: propBag.p || DEFAULT_P },
        glowColor: { type: 'c', value: color },
        viewVector: { type: 'v3', value: new THREE.Vector3(0.0, 0.0, 0.0) }
      },
      vertexShader: _emberStringify.default['glow-vertex.glsl'],
      fragmentShader: _emberStringify.default['glow-fragment.glsl'],
      side: THREE.FrontSide,
      blending: THREE.AdditiveBlending,
      transparent: true
    });

    var clone = new THREE.Mesh(geometry, shaderMaterial);
    var modifier = new THREE.SubdivisionModifier(3);
    modifier.modify(clone.geometry);

    clone.scale.multiplyScalar(propBag.scale || DEFAULT_SCALE);
    clone.renderOrder = 2;
    clone.name = 'glow';

    return clone;
  };

  ECGlowMesh.prototype = Object.create(THREE.Mesh.prototype);
  ECGlowMesh.prototype.constructor = ECGlowMesh;

  exports.default = ECGlowMesh;
});