define('em-city/controllers/application', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend({
    game: Ember.inject.service(),
    queryParams: {
      debugStats: 'stats',
      debugEmpty: 'empty',
      debugMute: 'mute',
      debugWin: 'win'
    },
    debugStats: null,
    debugEmpty: null,
    debugMute: null,
    debugWin: null,
    showGameUi: Ember.computed.equal('currentRouteName', 'play.index'),
    propMap: Ember.computed.alias('game.propMap'),
    timeLeft: Ember.computed.alias('propMap.timeLeft')
  });
});