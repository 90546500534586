define('em-city/game/ec-unit-shader', ['exports', 'em-city/ember-stringify'], function (exports, _emberStringify) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var COLOR = 0xFFFF00;
  var SPECULAR_COLOR = 0x111111;
  var SHININESS = 1000.0;
  var LIFESPAN = 500.0;

  var UNIFORMS = [THREE.ShaderLib['phong'].uniforms, { uTime: { value: 1.0 } }, { uLifespan: { value: LIFESPAN } }];

  var ECUnitShader = function ECUnitShader() {
    var uniforms = THREE.UniformsUtils.merge(UNIFORMS);
    uniforms.uColor1 = { value: new THREE.Color() };
    uniforms.uColor2 = { value: new THREE.Color() };
    uniforms.uOffset = { value: Math.random() * 2 * Math.PI };

    THREE.ShaderMaterial.call(this, {
      uniforms: uniforms,
      vertexShader: _emberStringify.default['phong-vertex.glsl'],
      fragmentShader: _emberStringify.default['unit-fragment.glsl']
    });

    this.lights = true;
    this.color = new THREE.Color(COLOR);
    this.specular = new THREE.Color(SPECULAR_COLOR);
    this.shininess = SHININESS;
    this.transparent = true;

    this.uniforms.diffuse.value = this.color;
    this.uniforms.specular.value = this.specular;
    this.uniforms.shininess.value = SHININESS;
    this.uniforms.opacity.value = 1.0;
  };

  ECUnitShader.prototype = Object.create(THREE.ShaderMaterial.prototype);
  ECUnitShader.prototype.constructor = ECUnitShader;

  exports.default = ECUnitShader;
});