define('em-city/routes/play/create-copy', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend({
    game: Ember.inject.service(),
    redirect: function redirect() {
      if (!this.get('game').get('ranOutOfTime')) {
        this.transitionTo('application');
      }
    }
  });
});