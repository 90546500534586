define('em-city/components/game-canvas', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    game: Ember.inject.service(),
    canvas: Ember.computed.alias('game.canvas'),
    onTop: Ember.computed.alias('game.onTop'),
    classNames: ['game-canvas-component'],
    classNameBindings: ['onTop:unblur:blur'],

    didInsertElement: function didInsertElement() {
      this.get('element').appendChild(this.get('canvas'));
    }
  });
});