define('em-city/game/util', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  function _toConsumableArray(arr) {
    if (Array.isArray(arr)) {
      for (var i = 0, arr2 = Array(arr.length); i < arr.length; i++) {
        arr2[i] = arr[i];
      }

      return arr2;
    } else {
      return Array.from(arr);
    }
  }

  // Debounce for one frame (assuming 60fps)
  var DEFAULT_DEBOUNCE_DELAY = Math.ceil(1000 / 60);

  function debounce(fn, context) {
    var delay = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : DEFAULT_DEBOUNCE_DELAY;

    var timeout = null;

    return function () {
      var args = arguments;
      clearTimeout(timeout);

      timeout = window.setTimeout(function () {
        return fn.apply(context, args);
      }, delay);
    };
  }

  function flatten(array) {
    var _Array$prototype;

    return (_Array$prototype = Array.prototype).concat.apply(_Array$prototype, _toConsumableArray(array));
  }

  function randomSign() {
    return Math.round(Math.random()) * 2 - 1;
  }

  function hasQueryParam(param) {
    return typeof URLSearchParams !== 'undefined' ? new URLSearchParams(window.location.search).has(param) : false;
  }

  function addQueryParam(param, value) {
    var params = new URLSearchParams(location.search);
    params.set(param, value);
    window.history.replaceState({}, '', location.pathname + '?' + params);
  }

  function animatables() {
    return this.children.filter(function (child) {
      return !!child.animate;
    });
  }

  function animateChildren(context, timestamp, camera) {
    context.animatables.forEach(function (animatable) {
      animatable.animate(timestamp, camera);
    });
  }

  function deserializeProperties(object, serializedObject) {
    Object.keys(serializedObject.properties).forEach(function (key) {
      object[key] = serializedObject.properties[key];
    });
  }

  exports.debounce = debounce;
  exports.flatten = flatten;
  exports.hasQueryParam = hasQueryParam;
  exports.addQueryParam = addQueryParam;
  exports.animatables = animatables;
  exports.animateChildren = animateChildren;
  exports.randomSign = randomSign;
  exports.deserializeProperties = deserializeProperties;
});