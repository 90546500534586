define('em-city/components/sector-visual', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    classNames: ['sector-visual-wrapper'],
    game: Ember.inject.service(),

    square: Ember.computed('sectorId', function () {
      return this.get('game').squareForIndex(this.get('sectorId'));
    }),

    didInsertElement: function didInsertElement() {
      this.set('resize', this.get('resize').bind(this));
      var visual = this.get('game').get('visual');
      var canvas = visual.renderer.domElement;
      var game = this.get('game');
      document.getElementById('js-sector-visual').appendChild(canvas);
      this.set('visual', visual);

      visual.configureSquare(this.get('square'), game.get('backgroundColor'));
      visual.start();
      this.set('visual', visual);

      this.addEventListeners();
    },
    willDestroyElement: function willDestroyElement() {
      window.removeEventListener('resize', this.get('resize'));
      this.get('visual').stop();
    },
    didRender: function didRender() {
      this.get('resize')();
    },
    addEventListeners: function addEventListeners() {
      window.addEventListener('resize', this.get('resize'));
    },
    resize: function resize() {
      var visual = this.get('visual');
      var element = this.get('element');
      var sectorButtons = document.getElementById('js-sector-buttons');
      resizeVisual(visual, element, sectorButtons);
    },


    emCubes: Ember.computed('square', function () {
      return this.get('square').emCubes;
    }),

    showUnitButtons: Ember.computed('emCubes', function () {
      return !!this.get('emCubes').length;
    }),

    showExtinguishButton: Ember.computed('emCubes', function () {
      return !!this.get('emCubes').find(function (cube) {
        return cube.overheated;
      });
    }),

    showRepairButton: Ember.computed('emCubes', function () {
      return !!this.get('emCubes').find(function (cube) {
        return cube.sabotaged;
      });
    }),

    showRemoveBuildingButton: Ember.computed('square', function () {
      return !this.get('square').building.removed;
    }),

    showEmCubeButtons: Ember.computed('showRemoveBuildingButton', function () {
      return !this.get('showRemoveBuildingButton');
    }),

    addCubeDisabled: Ember.computed('square', function () {
      return !this.get('square').canAddAnEmCube;
    }),

    removeCubeDisabled: Ember.computed('square', function () {
      return !this.get('square').canRemoveTopEmCube;
    }),

    addUnitDisabled: Ember.computed('square', function () {
      return !this.get('square').emWithSpaceForAUnit;
    }),

    removeSecurityUnitDisabled: Ember.computed('square', function () {
      return !this.get('square').securityUnits.length;
    }),

    removeCoolingUnitDisabled: Ember.computed('square', function () {
      return !this.get('square').coolingUnits.length;
    }),

    actions: {
      removeBuilding: function removeBuilding() {
        this.get('close')();
        this.get('game').actions.removeBuilding(this.get('square'));
      },

      addEmCube: function addEmCube() {
        this.get('close')();
        this.get('game').actions.addEmCube(this.get('square'));
      },

      removeEmCube: function removeEmCube() {
        this.get('close')();
        this.get('game').actions.removeEmCube(this.get('square'));
      },

      addCoolingUnit: function addCoolingUnit() {
        this.get('close')();
        this.get('game').actions.addCoolingUnit(this.get('square'));
      },

      removeCoolingUnit: function removeCoolingUnit() {
        this.get('close')();
        this.get('game').actions.removeCoolingUnit(this.get('square'));
      },

      addSecurityUnit: function addSecurityUnit() {
        this.get('close')();
        this.get('game').actions.addSecurityUnit(this.get('square'));
      },

      removeSecurityUnit: function removeSecurityUnit() {
        this.get('close')();
        this.get('game').actions.removeSecurityUnit(this.get('square'));
      },

      extinguishFire: function extinguishFire() {
        this.get('close')();
        this.get('game').actions.extinguishFire(this.get('square'));
      },

      repairSabotage: function repairSabotage() {
        this.get('close')();
        this.get('game').actions.repairSabotage(this.get('square'));
      }
    }
  });


  function resizeVisual(visual, element, sectorButtons) {
    var height = Math.max(element.clientHeight - sectorButtons.clientHeight, 1);
    var dimensions = { width: element.clientWidth, height: height, dpr: window.devicePixelRatio };
    visual.resize(dimensions);
  }
});