define("em-city/game/ec-renderer", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var MAX_WIDTH = 1280 * 2;
  var MAX_HEIGHT = 720 * 2;
  var MAX_RESOLUTION = MAX_WIDTH * MAX_HEIGHT;

  var ECRenderer = function ECRenderer() {
    THREE.WebGLRenderer.call(this, { alpha: true });
    this.shadowMap.enabled = true;
    this.shadowMap.type = THREE.BasicShadowMap;
    this.dimensions = { width: MAX_WIDTH, height: MAX_HEIGHT };
    this.scaleFactor = 1.0;
  };

  ECRenderer.prototype = Object.create(THREE.WebGLRenderer.prototype);
  ECRenderer.prototype.constructor = ECRenderer;
  ECRenderer.prototype.scaleWithDimensions = scaleWithDimensions;

  function scaleWithDimensions(dimensions) {
    var width = dimensions.width,
        height = dimensions.height;

    var scaleFactor = scaleFactorForRenderer(dimensions);
    this.scaleFactor = scaleFactor;
    this.dimensions = {
      width: width * scaleFactor,
      height: height * scaleFactor
    };

    this.setSize(this.dimensions.width, this.dimensions.height);
  }

  exports.default = ECRenderer;


  function scaleFactorForRenderer(dimensions) {
    var width = dimensions.width,
        height = dimensions.height,
        dpr = dimensions.dpr;

    return Math.min(Math.sqrt(MAX_RESOLUTION / (width * height * Math.pow(dpr, 2))), 1);
  }
});