define('em-city/components/main-menu', ['exports', 'em-city/game/sound-effects', 'em-city/game/svgs'], function (exports, _soundEffects, _svgs) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    game: Ember.inject.service(),
    classNames: ['main-menu'],
    productionIcon: _svgs.default['production'],
    playerName: Ember.computed.alias('game.name'),

    init: function init() {
      this._super.apply(this, arguments);
      this.set('soundEffects', _soundEffects.default.create());
    },


    muted: Ember.computed('soundEffects.muted', function () {
      return this.get('soundEffects').get('muted');
    }),

    actions: {
      toggleMute: function toggleMute() {
        var muted = this.get('soundEffects').get('muted');
        this.soundEffects.mute(!muted);
      },

      play: function play() {
        this.soundEffects.play('intro');
      },

      reset: function reset() {
        this.get('game').reset();
      }
    }
  });
});