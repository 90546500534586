define('em-city/game/scene-manager', ['exports', 'em-city/game/ec-scene', 'em-city/game/ec-ground', 'em-city/game/ec-sun', 'em-city/game/ec-moon', 'em-city/game/ec-clouds', 'em-city/game/ec-ambient-light', 'em-city/game/ec-directional-light', 'em-city/game/ec-stars', 'em-city/game/model-downloader', 'em-city/game/ec-texture-loader-promise', 'em-city/game/util', 'em-city/game/constants'], function (exports, _ecScene, _ecGround, _ecSun, _ecMoon, _ecClouds, _ecAmbientLight, _ecDirectionalLight, _ecStars, _modelDownloader, _ecTextureLoaderPromise, _util, _constants) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  function _toConsumableArray(arr) {
    if (Array.isArray(arr)) {
      for (var i = 0, arr2 = Array(arr.length); i < arr.length; i++) {
        arr2[i] = arr[i];
      }

      return arr2;
    } else {
      return Array.from(arr);
    }
  }

  function _classCallCheck(instance, Constructor) {
    if (!(instance instanceof Constructor)) {
      throw new TypeError("Cannot call a class as a function");
    }
  }

  var _createClass = function () {
    function defineProperties(target, props) {
      for (var i = 0; i < props.length; i++) {
        var descriptor = props[i];
        descriptor.enumerable = descriptor.enumerable || false;
        descriptor.configurable = true;
        if ("value" in descriptor) descriptor.writable = true;
        Object.defineProperty(target, descriptor.key, descriptor);
      }
    }

    return function (Constructor, protoProps, staticProps) {
      if (protoProps) defineProperties(Constructor.prototype, protoProps);
      if (staticProps) defineProperties(Constructor, staticProps);
      return Constructor;
    };
  }();

  var SPIN_TO_STOP_DURATION = 3000;
  var SPIN_SPEED = 0.005;
  var MIN_SPIN_AMOUNT = Math.PI;
  var GRAVITY = -7.0;
  var BROADPHASE_SWEEP_PRUNE = 2;
  var PHYSICS_ITERATIONS = 8;
  var TIME_STEP = 1 / 60;
  var BLOW_UP_SMOKE_COLOR = new THREE.Color(0x000000);
  var MIN_BLOW_UP_XZ_VEL = 0.1;
  var MAX_BLOW_UP_XZ_VEL = 0.2;
  var MIN_BLOW_UP_Y_VEL = 2.0;
  var MAX_BLOW_UP_Y_VEL = 3.0;
  var BLOW_UP_CUBE_FADE_DURATION = 5000;

  var SceneManager = function () {
    function SceneManager(camera) {
      _classCallCheck(this, SceneManager);

      this.camera = camera;
      this.meshHashes = [];
      this.timestamp = null;
      this.demoMode = true;
      this.ground = new _ecGround.default();
    }

    _createClass(SceneManager, [{
      key: 'configureScene',
      value: function configureScene() {
        this.scene = new _ecScene.default();
        this.scene.add(this.camera);
        this.configureLights();
        this.configurePhysics();
        this.addMeshesToScene();
        this.updateScene(null);
      }
    }, {
      key: 'configureLights',
      value: function configureLights() {
        this.ambientLight = new _ecAmbientLight.default();
        this.scene.add(this.ambientLight);
        this.scene.add(new _ecDirectionalLight.default());
      }
    }, {
      key: 'configurePhysics',
      value: function configurePhysics() {
        this.world = new OIMO.World({
          timestep: TIME_STEP,
          iterations: PHYSICS_ITERATIONS,
          broadphase: BROADPHASE_SWEEP_PRUNE,
          gravity: [0, GRAVITY, 0]
        });
      }
    }, {
      key: 'addMeshesToScene',
      value: function addMeshesToScene() {
        this.addGroundToScene();
        this.addSunToScene();
        this.addMoonToScene();
        this.addStarsToScene();
        this.addCloudsToScene();
        this.addBuildingsToScene();
      }
    }, {
      key: 'loadGameState',
      value: function loadGameState(gameState) {
        var _this = this;

        this.ground.deserialize(gameState.ground);
        this.ground.squares.forEach(function (square) {
          var stateSquares = gameState.ground.squares;
          var stateSquare = stateSquares.find(function (stateSquare) {
            return stateSquare.row === square.row && stateSquare.column == square.column;
          });

          _this.loadBuildingFromState(square, stateSquare);
          _this.loadSquareState(square, stateSquare);
        });
      }
    }, {
      key: 'loadSquareState',
      value: function loadSquareState(square, stateSquare) {
        stateSquare.cubes.forEach(function (stateCube) {
          square.deserializeAndAddEm(stateCube);
          stateCube.units.forEach(function (stateUnit) {
            square.deserializeAndAddUnit(stateUnit);
          });
        });
      }
    }, {
      key: 'loadBuildingFromState',
      value: function loadBuildingFromState(square, stateSquare) {
        var building = this.buildingCloneForBuildingName(stateSquare.building.properties.buildingName);
        building.deserialize(stateSquare.building);
        this.addBuildingToSquare(building, square);
        if (building.removed) square.removeBuilding();
      }
    }, {
      key: 'downloadAssets',
      value: function downloadAssets() {
        var _this2 = this;

        return (0, _modelDownloader.downloadMeshes)().then(function (downloads) {
          _this2.meshHashes = downloads.filter(function (download) {
            return download.type === 'mesh';
          });
        }).then(function () {
          return _this2.cacheTextures();
        }).then(function (textures) {
          return THREE.Cache.textures = textures;
        });
      }
    }, {
      key: 'cacheTextures',
      value: function cacheTextures() {
        var promiseHash = {};
        promiseHash[_constants.TEXTURE_PATH_CLOUD] = (0, _ecTextureLoaderPromise.textureLoaderPromise)(_constants.TEXTURE_PATH_CLOUD);
        promiseHash[_constants.TEXTURE_PATH_STAR] = (0, _ecTextureLoaderPromise.textureLoaderPromise)(_constants.TEXTURE_PATH_STAR);
        return Ember.RSVP.hash(promiseHash);
      }
    }, {
      key: 'scaleWithDimensions',
      value: function scaleWithDimensions(dimensions) {
        this.scene.scaleWithDimensions(dimensions);
      }
    }, {
      key: 'highlightSquare',
      value: function highlightSquare(square) {
        if (!square) return;
        square.highlight();
        var children = touchableObjectsFunction(square.children);
        children.forEach(function (child) {
          return child.highlight();
        });
      }
    }, {
      key: 'unhighlightEverything',
      value: function unhighlightEverything() {
        this.touchableObjects.forEach(function (object) {
          object.unhighlight();
        });
      }
    }, {
      key: 'animate',
      value: function animate(timestamp, camera) {
        this.timestamp = timestamp;
        this.world.step();
        this.scene.animate(timestamp, camera);
        if (this.demoMode) this.rotate();
      }
    }, {
      key: 'rotate',
      value: function rotate() {
        this.ground.rotation.y += SPIN_SPEED;
        this.ground.rotation.y = this.ground.rotation.y % (2 * Math.PI);
      }
    }, {
      key: 'turnOnDemoMode',
      value: function turnOnDemoMode() {
        this.demoMode = true;
      }
    }, {
      key: 'turnOffDemoMode',
      value: function turnOffDemoMode() {
        this.demoMode = false;
        if (!this.ground) return;

        var finalRotation = 2 * Math.PI;
        var rotationDifference = Math.abs(this.ground.rotation.y - finalRotation);

        if (rotationDifference < MIN_SPIN_AMOUNT) finalRotation *= 2;

        new TWEEN.Tween(this.ground.rotation).to({ x: 0, y: finalRotation, z: 0 }, SPIN_TO_STOP_DURATION).easing(TWEEN.Easing.Elastic.Out).start();
      }
    }, {
      key: 'blowUpEmTower',
      value: function blowUpEmTower(square) {
        var _ground;

        var clonedCubes = square.emCubes.map(function (cube) {
          return cube.clone();
        });
        configureClonedCubes(clonedCubes, square, this.ground, this.world);

        square.addSmoke(this.timestamp, {
          color: BLOW_UP_SMOKE_COLOR,
          blending: THREE.NormalBlending
        });

        blowUpCubes(clonedCubes);
        fadeCubesOut(clonedCubes);

        square.removeEmCubes();
        (_ground = this.ground).add.apply(_ground, _toConsumableArray(clonedCubes));
      }
    }, {
      key: 'addSmoke',
      value: function addSmoke(square) {
        square.addSmoke(this.timestamp);
      }
    }, {
      key: 'updateScene',
      value: function updateScene(sceneTime) {
        if (this.sceneTime === sceneTime) return;
        this.sceneTime = sceneTime || 0;

        if (this.sun) this.sun.update(sceneTime);
        if (this.moon) this.moon.update(sceneTime);
        this.updateBackground(sceneTime);
      }
    }, {
      key: 'updateBackground',
      value: function updateBackground(timestamp) {
        this.scene.adjustBackgroundLightness(Math.cos(timestamp));
        this.stars.adjustOpacity(-Math.cos(timestamp));
        this.clouds.adjustOpacity(Math.cos(timestamp));
        document.body.style.backgroundColor = this.backgroundColorStyle;
      }
    }, {
      key: 'addObjectToScene',
      value: function addObjectToScene(object) {
        this.scene.add(object);
      }
    }, {
      key: 'removeObjectFromScene',
      value: function removeObjectFromScene(object) {
        this.scene.remove(object);
      }
    }, {
      key: 'removeBodyFromWorld',
      value: function removeBodyFromWorld(body) {
        this.world.removeRigidBody(body);
      }
    }, {
      key: 'addSunToScene',
      value: function addSunToScene() {
        this.sun = new _ecSun.default();
        this.scene.add(this.sun);
      }
    }, {
      key: 'addMoonToScene',
      value: function addMoonToScene() {
        this.moon = new _ecMoon.default();
        this.scene.add(this.moon);
      }
    }, {
      key: 'addStarsToScene',
      value: function addStarsToScene() {
        this.stars = new _ecStars.default(this.ground.bounds);
        this.scene.add(this.stars);
      }
    }, {
      key: 'addCloudsToScene',
      value: function addCloudsToScene() {
        this.clouds = new _ecClouds.default();
        this.scene.add(this.clouds);
      }
    }, {
      key: 'addGroundToScene',
      value: function addGroundToScene() {
        this.ground = new _ecGround.default();
        this.scene.add(this.ground);
        this.ground.body = this.world.add(this.ground.physicsProps);
      }
    }, {
      key: 'addBuildingsToScene',
      value: function addBuildingsToScene() {
        var _this3 = this;

        this.ground.squares.forEach(function (square) {
          var buildingData = _this3.randomBuildingData;
          var building = buildingData.mesh.clone();
          _this3.addBuildingToSquare(building, square);
        });
      }
    }, {
      key: 'addBuildingToSquare',
      value: function addBuildingToSquare(building, square) {
        square.replaceBuilding(building);
        addBuildingPhysics(building, square, this.ground, this.world);
      }
    }, {
      key: 'buildingCloneForBuildingName',
      value: function buildingCloneForBuildingName(name) {
        return this.buildingDatas.find(function (data) {
          return data.buildingName === name;
        }).mesh.clone();
      }
    }, {
      key: 'indexOfSquare',
      value: function indexOfSquare(square) {
        return this.ground.squares.indexOf(square);
      }
    }, {
      key: 'squareForIndex',
      value: function squareForIndex(index) {
        return this.ground.squares[index];
      }
    }, {
      key: 'buildingDatas',
      get: function get() {
        return this.meshHashes.filter(function (meshHash) {
          return meshHash.mesh.name === 'building';
        });
      }
    }, {
      key: 'randomBuildingData',
      get: function get() {
        return this.buildingDatas[Math.floor(Math.random() * this.buildingDatas.length)];
      }
    }, {
      key: 'backgroundColor',
      get: function get() {
        return this.scene.background;
      }
    }, {
      key: 'backgroundColorStyle',
      get: function get() {
        return this.backgroundColor.getStyle();
      }
    }, {
      key: 'emCubes',
      get: function get() {
        return this.ground.emCubes;
      }
    }, {
      key: 'squares',
      get: function get() {
        return this.ground.squares;
      }
    }, {
      key: 'scaleFactor',
      get: function get() {
        return this.scene.scale.x;
      }
    }, {
      key: 'serialized',
      get: function get() {
        return { ground: this.ground.serialized };
      }
    }, {
      key: 'touchableObjects',
      get: function get() {
        return touchableObjectsFunction(this.ground.children);
      }
    }]);

    return SceneManager;
  }();

  exports.default = SceneManager;


  function touchableObjectsFunction(objects) {
    return objects.reduce(function (array, object) {
      if (object.touchable) array.push(object);
      array.push.apply(array, _toConsumableArray(touchableObjectsFunction(object.children)));
      return array;
    }, []);
  }

  function blowUpCubes(cubes) {
    cubes.forEach(function (cube) {
      var xVel = (0, _util.randomSign)() * THREE.Math.randFloat(MIN_BLOW_UP_XZ_VEL, MAX_BLOW_UP_XZ_VEL);
      var zVel = (0, _util.randomSign)() * THREE.Math.randFloat(MIN_BLOW_UP_XZ_VEL, MAX_BLOW_UP_XZ_VEL);
      var yVel = THREE.Math.randFloat(MIN_BLOW_UP_Y_VEL, MAX_BLOW_UP_Y_VEL);
      var vel = new OIMO.Vec3(xVel, yVel, zVel);
      var pos = new OIMO.Vec3(0, 0, 0);
      cube.body.applyImpulse(pos, vel);
    });
  }

  function configureClonedCubes(cubes, square, ground, world) {
    cubes.forEach(function (cube) {
      var pos = ground.worldToLocal(square.getWorldPosition());
      var cubePos = ground.worldToLocal(cube.getWorldPosition());
      var props = cube.physicsProps;
      cube.position.x = pos.x;
      cube.position.y = pos.y + cubePos.y;
      cube.position.z = pos.z;
      props.pos = [cube.position.x, cube.position.y, cube.position.z];
      cube.body = world.add(props);
      cube.touchable = false;
      cube.unbust();
      cube.name = 'cloned-cube';
      cube.material.transparent = true;
      cube.traverse(function (object) {
        return object.touchable = false;
      });
    });
  }

  function fadeCubesOut(cubes) {
    cubes.forEach(function (cube) {
      new TWEEN.Tween({ opacity: 1.0 }).to({ opacity: 0.0 }, BLOW_UP_CUBE_FADE_DURATION).onUpdate(function (object) {
        cube.material.opacity = object.opacity;
        cube.children.forEach(function (child) {
          child.material.opacity = object.opacity;
        });
      }).onComplete(function () {
        cube.body.remove();
        cube.parent.remove(cube);
      }).easing(TWEEN.Easing.Cubic.InOut).start();
    });
  }

  function addBuildingPhysics(building, square, ground, world) {
    var pos = building.getWorldPosition();
    var squareWorldPos = ground.worldToLocal(square.getWorldPosition());
    var squareOffset = square.height / 2.0;
    var buildingOffset = building.physicsProps.size[1] / 2.0;
    pos.y = squareWorldPos.y + squareOffset + buildingOffset;

    var props = Object.assign({ pos: [squareWorldPos.x, pos.y, squareWorldPos.z] }, building.physicsProps);

    building.body = world.add(props);
  }
});