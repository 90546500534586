define("em-city/game/ec-texture-loader-promise", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  function textureLoaderPromise(path) {
    return new Promise(function (resolve, reject) {
      new THREE.TextureLoader().load(path, function (texture) {
        return resolve(texture);
      }, null, function (xhr) {
        return reject(xhr);
      });
    });
  }

  exports.textureLoaderPromise = textureLoaderPromise;
});