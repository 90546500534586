define('em-city/components/game-menu', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var getOwner = Ember.getOwner;
  exports.default = Ember.Component.extend({
    classNames: ['menu'],
    router: Ember.inject.service(),
    game: Ember.inject.service(),
    closable: true,

    didInsertElement: function didInsertElement() {
      var element = this.get('element');
      var game = this.get('game');
      element.style.backgroundColor = game.get('backgroundColorStyle');
    },


    actions: {
      close: function close() {
        var current = getOwner(this).lookup('controller:application').currentPath;
        var parent = current.substring(0, current.lastIndexOf('.'));
        this.get('router').transitionTo(parent);
      }
    }
  });
});