define('em-city/controllers/play/create-copy', ['exports', 'em-city/game/constants'], function (exports, _constants) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend({
    game: Ember.inject.service(),
    timeLeft: Ember.computed.alias('game.propMap.timeLeft'),
    ranOutOfTime: Ember.computed.alias('game.ranOutOfTime'),

    actions: {
      resetTime: function resetTime() {
        this.set('timeLeft', _constants.ERA_MS);
        this.set('ranOutOfTime', false);
      },
      reset: function reset() {
        this.get('game').reset();
      }
    }
  });
});