define('em-city/components/property-button', ['exports', 'em-city/util/animate-value-task', 'em-city/game/svgs'], function (exports, _animateValueTask, _svgs) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    tagName: 'button',
    classNames: ['button'],
    classNameBindings: ['classForType', 'alertClassForType'],

    classForType: Ember.computed('type', function () {
      return 'button--' + this.get('type');
    }),

    alertClassForType: Ember.computed('type', 'score', 'animateValueTask.isRunning', function () {
      if (!this.get('shouldAnimateColor')) return '';
      return 'button--alert-animation--' + this.get('type');
    }),

    shouldAnimateColor: Ember.computed(function () {
      return this.get('score') > this.get('displayScore') && this.get('animateValueTask.isRunning');
    }).volatile(),

    hasScore: Ember.computed('score', function () {
      return typeof this.get('score') !== 'undefined';
    }),

    icon: Ember.computed('type', function () {
      return _svgs.default[this.get('type')];
    }),

    displayScore: 0,

    animateValueTask: _animateValueTask.default,

    didReceiveAttrs: function didReceiveAttrs() {
      var _this = this;

      var score = this.get('score');
      var displayScore = this.get('displayScore');
      if (score === displayScore) return;
      this.get('animateValueTask').perform(displayScore, score, function (updatedValue) {
        return _this.set('displayScore', Math.round(updatedValue));
      });
    }
  });
});