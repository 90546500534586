define('em-city/game/sound-effects', ['exports', 'em-city/game/util', 'em-city/config/environment'], function (exports, _util, _environment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var VOLUME = 0.25;

  var EFFECT_NAMES = ['enter', 'leave', 'intro', 'add', 'remove'];

  var SoundEffects = Ember.Object.extend({
    singletonParams: Ember.Object.create({ muted: false }),
    init: function init() {
      this._super.apply(this, arguments);
      this.preloadSoundEffects();
    },


    muted: Ember.computed('singletonParams.muted', function () {
      return !!((0, _util.hasQueryParam)('mute') || this.get('singletonParams.muted'));
    }),

    mute: function mute(shouldMute) {
      this.get('singletonParams').set('muted', shouldMute);
      this.notifyPropertyChange('muted');
    },
    preloadSoundEffects: function preloadSoundEffects() {
      if (this.get('soundEffects')) return;
      this.set('soundEffects', EFFECT_NAMES.reduce(function (object, effectName) {
        object[effectName] = preloadSoundEffect(effectName);
        return object;
      }, {}));
    },
    play: function play(effectName) {
      if (this.get('muted')) return;
      this.get('soundEffects')[effectName].play();
    }
  });

  function preloadSoundEffect(effectName) {
    return new Howl({
      src: ['/sound-effects/' + effectName + '.mp3'],
      volume: VOLUME
    });
  }

  exports.default = SoundEffects;
});