define("em-city/game/ec-ambient-light", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var AMBIENT_LIGHT_COLOR = 0xFFFFFF;
  var AMBIENT_LIGHT_INTENSITY = 0.75;

  var ARGS = [AMBIENT_LIGHT_COLOR, AMBIENT_LIGHT_INTENSITY];

  var ECAmbientLight = function ECAmbientLight() {
    THREE.AmbientLight.apply(this, ARGS);
  };

  ECAmbientLight.prototype = Object.create(THREE.AmbientLight.prototype);
  ECAmbientLight.prototype.constructor = ECAmbientLight;

  exports.default = ECAmbientLight;
});