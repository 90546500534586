define('em-city/game/touch-handler', ['exports', 'em-city/game/util'], function (exports, _util) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  function _classCallCheck(instance, Constructor) {
    if (!(instance instanceof Constructor)) {
      throw new TypeError("Cannot call a class as a function");
    }
  }

  var _createClass = function () {
    function defineProperties(target, props) {
      for (var i = 0; i < props.length; i++) {
        var descriptor = props[i];
        descriptor.enumerable = descriptor.enumerable || false;
        descriptor.configurable = true;
        if ("value" in descriptor) descriptor.writable = true;
        Object.defineProperty(target, descriptor.key, descriptor);
      }
    }

    return function (Constructor, protoProps, staticProps) {
      if (protoProps) defineProperties(Constructor.prototype, protoProps);
      if (staticProps) defineProperties(Constructor, staticProps);
      return Constructor;
    };
  }();

  var TouchHanlder = function () {
    function TouchHanlder(camera, canvas, tapCallback) {
      _classCallCheck(this, TouchHanlder);

      this.camera = camera;
      this.canvas = canvas;
      this.touching = false;
      this.touchableObjects = [];
      this.tapCallback = tapCallback;
      this.cursorPosition = new THREE.Vector2(-1, -1);
      this.raycaster = new THREE.Raycaster();
      this.addEventListeners();
    }

    _createClass(TouchHanlder, [{
      key: 'bindEventListeners',
      value: function bindEventListeners() {
        this.touchStart = (0, _util.debounce)(this.touchStart, this);
        this.touchMove = this.touchMove.bind(this);
        this.touchUp = (0, _util.debounce)(this.touchUp, this);
      }
    }, {
      key: 'addEventListeners',
      value: function addEventListeners() {
        this.bindEventListeners();

        this.canvas.addEventListener('mousedown', this.touchStart, false);
        this.canvas.addEventListener('mousemove', this.touchMove, false);
        this.canvas.addEventListener('mouseup', this.touchUp, false);

        this.canvas.addEventListener('touchstart', this.touchStart, false);
        this.canvas.addEventListener('touchmove', this.touchMove, false);
        this.canvas.addEventListener('touchend', this.touchUp, false);
      }
    }, {
      key: 'configureTouchableObjects',
      value: function configureTouchableObjects(objects) {
        this.touchableObjects = objects;
      }
    }, {
      key: 'touchStart',
      value: function touchStart(event) {
        this.touching = true;
        this.cursorPosition = cursorPositionFromEvent(event);
        this.startSquare = this.touchedSquare;
      }
    }, {
      key: 'touchMove',
      value: function touchMove(event) {
        this.cursorPosition = cursorPositionFromEvent(event);
      }
    }, {
      key: 'touchUp',
      value: function touchUp() {
        this.touching = false;
        var square = this.touchedSquare;
        if (this.sameSquareAsStartSquare(square)) this.tapCallback(square);
        this.startSquare = null;
        this.cursorPosition = null;
      }
    }, {
      key: 'sameSquareAsStartSquare',
      value: function sameSquareAsStartSquare(square) {
        return square && this.startSquare === square;
      }
    }, {
      key: 'intersection',
      value: function intersection(position, objects) {
        if (!position) return null;
        this.raycaster.setFromCamera(position, this.camera);
        var intersects = this.raycaster.intersectObjects(objects);
        return intersects[0];
      }
    }, {
      key: 'squareToHighlight',
      get: function get() {
        var square = this.touchedSquare;

        if (this.touching && !this.sameSquareAsStartSquare(square)) {
          square = null;
        }

        return square;
      }
    }, {
      key: 'touchedSquare',
      get: function get() {
        var intersection = this.intersection(this.cursorPosition, this.touchableObjects);
        if (!intersection) return null;

        var object = intersection.object;
        if (object.square) object = object.square;
        return object;
      }
    }]);

    return TouchHanlder;
  }();

  exports.default = TouchHanlder;


  function cursorPositionFromEvent(event) {
    var touch = event;
    if (event.touches) {
      touch = event.touches[0];
    }

    // calculate mouse position in normalized device coordinates
    // (-1 to +1) for both components
    var x = touch.clientX / window.innerWidth * 2 - 1;
    var y = -(touch.clientY / window.innerHeight) * 2 + 1;
    return new THREE.Vector2(x, y);
  }
});