define('em-city/game/game-action-animations', ['exports', 'em-city/game/constants'], function (exports, _constants) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.winAnimation = exports.animateShakeGround = exports.animateRemoveBuilding = undefined;


  var GROUND_SHAKE_HEIGHT = 5.0;
  var LAUNCHED_CUBE_TOWER_Y_POSITION = 100;
  var LAUNCH_ANIMATION_DURATION = 5000;
  var MIN_LAUNCH_DELAY = 500;
  var MAX_LAUNCH_DELAY = 2000;
  var WIN_SPIN_DURATION = 4000;
  var WIN_SPIN_DELAY = 1000;

  function animateRemoveBuilding(square) {
    var ghostBuilding = addGhostBuilding(square);
    ghostBuilding.sink(square);
    square.removeBuilding();
  }

  function animateShakeGround(ground) {
    var finalPosition = ground.finalPosition;
    ground.position.setY(finalPosition.y + GROUND_SHAKE_HEIGHT);

    new TWEEN.Tween(ground.position).to(finalPosition, _constants.ANIMATION_DURATION).easing(TWEEN.Easing.Elastic.Out).start();
  }

  function addGhostBuilding(square) {
    var ghostBuilding = square.building.ghostClone();
    ghostBuilding.addToSquare(square);
    return ghostBuilding;
  }

  function winAnimation(scene, ground, callback) {
    launchCubeTowers(scene, ground);
    rotateCityOnWin(ground, callback);
  }

  function rotateCityOnWin(ground, callback) {
    window.setTimeout(function () {
      var finalRotation = ground.rotation.y + 2 * Math.PI;
      var spinTween = new TWEEN.Tween(ground.rotation).to({ x: 0, y: finalRotation, z: 0 }, WIN_SPIN_DURATION).onComplete(function () {
        return callback();
      }).easing(TWEEN.Easing.Quadratic.InOut);

      spinTween.start();
    }, WIN_SPIN_DELAY);
  }

  function launchCubeTowers(scene, ground) {
    ground.squaresWithCubes.forEach(function (square) {
      var emCubeTower = square.emCubeTower;
      ground.add(emCubeTower);
      var finalPosition = emCubeTower.position.clone().setY(LAUNCHED_CUBE_TOWER_Y_POSITION);

      var towerTween = new TWEEN.Tween(emCubeTower.position).to(finalPosition, LAUNCH_ANIMATION_DURATION).onComplete(function () {
        return ground.remove(emCubeTower);
      }).easing(TWEEN.Easing.Circular.In);

      var delay = THREE.Math.randFloat(MIN_LAUNCH_DELAY, MAX_LAUNCH_DELAY);
      window.setTimeout(function () {
        return towerTween.start();
      }, delay);
    });
  }

  exports.animateRemoveBuilding = animateRemoveBuilding;
  exports.animateShakeGround = animateShakeGround;
  exports.winAnimation = winAnimation;
});