define('em-city/game/cursor-manager', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  function _classCallCheck(instance, Constructor) {
    if (!(instance instanceof Constructor)) {
      throw new TypeError("Cannot call a class as a function");
    }
  }

  var _createClass = function () {
    function defineProperties(target, props) {
      for (var i = 0; i < props.length; i++) {
        var descriptor = props[i];
        descriptor.enumerable = descriptor.enumerable || false;
        descriptor.configurable = true;
        if ("value" in descriptor) descriptor.writable = true;
        Object.defineProperty(target, descriptor.key, descriptor);
      }
    }

    return function (Constructor, protoProps, staticProps) {
      if (protoProps) defineProperties(Constructor.prototype, protoProps);
      if (staticProps) defineProperties(Constructor, staticProps);
      return Constructor;
    };
  }();

  var CURSOR_DEFAULT = 'default';
  var CURSOR_POINTER = 'pointer';

  var CursorManager = function () {
    function CursorManager(canvas) {
      _classCallCheck(this, CursorManager);

      this.canvas = canvas;
      this._state = CURSOR_DEFAULT;
    }

    _createClass(CursorManager, [{
      key: 'update',
      value: function update(isTouchingSomething) {
        var newState = CURSOR_DEFAULT;
        if (isTouchingSomething) newState = CURSOR_POINTER;

        this.state = newState;
      }
    }, {
      key: 'state',
      get: function get() {
        return this._state;
      },
      set: function set(newState) {
        var classList = this.canvas.classList;
        if (Array.from(classList).includes(newState)) {
          return;
        }
        classList.remove(this.state);
        this._state = newState;
        classList.add(this.state);
      }
    }]);

    return CursorManager;
  }();

  exports.default = CursorManager;
});