define('em-city/game/ec-building', ['exports', 'em-city/game/ec-building-shader', 'em-city/game/util', 'em-city/game/constants'], function (exports, _ecBuildingShader, _util, _constants) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var MIN_INSURRECTION = 5;
  var MAX_INSURRECTION = 15;
  var HEIGHT = 8.0;
  var WIDTH = 2.0;
  var REMOVED_BUILDING_Y_POSITION = -10.0;
  var FINAL_BUILDING_POSITION = { y: '' + REMOVED_BUILDING_Y_POSITION };

  var ECBuilding = function ECBuilding(geometry, buildingName) {
    var material = new _ecBuildingShader.default();
    THREE.Mesh.call(this, geometry, material);

    this.castShadow = false;
    this.receiveShadow = false;
    this.name = 'building';
    this.touchable = true;
    this.sinkingTween = null;
    this.square = null;

    this.removed = false;
    this.insurrectionIfRemoved = THREE.Math.randInt(MIN_INSURRECTION, MAX_INSURRECTION);
    this.buildingName = buildingName;
    this.material.color.setHex(this.unhighlightColorHex);

    this.physicsProps = {
      type: 'box',
      size: [WIDTH, HEIGHT, WIDTH],
      move: false
    };
  };

  ECBuilding.prototype = Object.create(THREE.Mesh.prototype);
  ECBuilding.prototype.constructor = ECBuilding;
  ECBuilding.prototype.highlight = highlight;
  ECBuilding.prototype.unhighlight = unhighlight;
  ECBuilding.prototype.sink = sink;
  ECBuilding.prototype.ghostClone = ghostClone;
  ECBuilding.prototype.clone = clone;
  ECBuilding.prototype.removeFromSquare = removeFromSquare;
  ECBuilding.prototype.addToSquare = addToSquare;
  ECBuilding.prototype.deserialize = deserialize;

  Object.defineProperty(ECBuilding.prototype, 'unhighlightColorHex', { get: unhighlightColorHex });

  Object.defineProperty(ECBuilding.prototype, 'serialized', { get: serialized });

  exports.default = ECBuilding;


  function highlight() {
    this.material.color.setHex(_constants.HIGHLIGHT_COLOR);
  }

  function unhighlight() {
    this.material.color.setHex(this.unhighlightColorHex);
  }

  function sink() {
    var _this = this;

    this.sinkingTween = new TWEEN.Tween(this.position).to(FINAL_BUILDING_POSITION, _constants.ANIMATION_DURATION).onUpdate(function () {
      return updateSink(_this);
    }).onComplete(function () {
      _this.removeFromSquare(_this);
      _this.sinkingTween = null;
    }).start();
  }

  function updateSink(ghostBuilding) {
    ghostBuilding.material.uniforms.uLocalMatrix.value = ghostBuilding.matrix;
  }

  function ghostClone() {
    var clone = this.clone();
    clone.touchable = false;
    clone.name = 'building-ghost';
    return clone;
  }

  function clone() {
    var clone = new this.constructor(this.geometry, this.buildingName);
    clone.deserialize(this.serialized);
    if (this.sinkingTween) clone.sink();
    return clone;
  }

  function removeFromSquare() {
    if (!this.parent) return;
    this.parent.remove(this);
    this.removed = true;
  }

  function addToSquare(square) {
    square.add(this);
    this.square = square;
  }

  function unhighlightColorHex() {
    var colorOffset = 1 - this.insurrectionIfRemoved / (MAX_INSURRECTION - MIN_INSURRECTION);
    return new THREE.Color().setHex(_constants.BUILDING_COLOR).offsetHSL(colorOffset, 0, 0).getHex();
  }

  function serialized() {
    return {
      properties: {
        buildingName: this.buildingName,
        removed: this.removed,
        insurrectionIfRemoved: this.insurrectionIfRemoved
      }
    };
  }

  function deserialize(serialized) {
    (0, _util.deserializeProperties)(this, serialized);
    this.unhighlight();
  }
});