define('em-city/components/property-buttons', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    game: Ember.inject.service(),
    classNames: ['buttons', 'buttons-top'],
    production: Ember.computed.alias('displayProps.production'),
    heat: Ember.computed.alias('displayProps.heat'),
    insurrection: Ember.computed.alias('displayProps.insurrection')
  });
});