define('em-city/game/ec-moon', ['exports', 'em-city/game/ec-sun'], function (exports, _ecSun) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var ECMoon = function ECMoon() {
    _ecSun.default.call(this);
    this.name = 'moon';
  };

  ECMoon.prototype = Object.create(_ecSun.default.prototype);
  ECMoon.prototype.constructor = ECMoon;
  ECMoon.prototype.RADIUS = 0.25;
  ECMoon.prototype.MATERIAL_COLOR = 0xE0ECFF;
  ECMoon.prototype.WAVE_OFFSET = Math.PI;
  ECMoon.prototype.LIGHT_ARGS = [0xFFFFFF, 0.75, 0.0, 1.0];

  exports.default = ECMoon;
});