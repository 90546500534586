define('em-city/game/ec-sun', ['exports', 'em-city/game/ec-glow-mesh'], function (exports, _ecGlowMesh) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  function _toConsumableArray(arr) {
    if (Array.isArray(arr)) {
      for (var i = 0, arr2 = Array(arr.length); i < arr.length; i++) {
        arr2[i] = arr[i];
      }

      return arr2;
    } else {
      return Array.from(arr);
    }
  }

  var GLOW_PROP_BAG = {
    p: 5.0,
    scale: 2.0
  };

  var ECSun = function ECSun() {
    var geometry = new THREE.SphereGeometry(this.RADIUS, this.SEGMENTS, this.SEGMENTS);
    var material = new THREE.MeshBasicMaterial({ color: this.MATERIAL_COLOR });
    THREE.Mesh.call(this, geometry, material);

    this.cameraVector = new THREE.Vector3();
    this.add(new (Function.prototype.bind.apply(THREE.PointLight, [null].concat(_toConsumableArray(this.LIGHT_ARGS))))());
    this.createGlow();
    this.update(0);
    this.name = 'sun';
  };

  ECSun.prototype = Object.create(THREE.Mesh.prototype);
  ECSun.prototype.constructor = ECSun;
  ECSun.prototype.update = update;
  ECSun.prototype.animate = animate;
  ECSun.prototype.createGlow = createGlow;
  ECSun.prototype.SEGMENTS = 16;
  ECSun.prototype.RADIUS = 1;
  ECSun.prototype.MATERIAL_COLOR = 0xFFD700;
  ECSun.prototype.ORBIT_RADIUS = 7;
  ECSun.prototype.Y_OFFSET = 1;
  ECSun.prototype.WAVE_OFFSET = 0;
  ECSun.prototype.LIGHT_ARGS = [0xFFFF00, 1.5, 0.0, 1.0];
  ECSun.prototype.viewVector = new THREE.Vector3();

  exports.default = ECSun;


  function animate(timestamp, camera) {
    // WHY DOES THIS NOT WORK?!?!!?!?
    //this.cameraVector.copy(camera.getWorldPosition());
    //this.worldToLocal(this.cameraVector);
    // (note that if you go local->world instead of world->local... it works too...
    //this.localToWorld(this.cameraVector);

    // BUT THIS DOES??!?!?
    this.cameraVector.copy(camera.position).applyMatrix4(this.matrix);

    this.viewVector.subVectors(this.cameraVector, this.position);
    this.glow.material.uniforms.viewVector.value = this.viewVector;
  }

  function createGlow() {
    this.glow = new _ecGlowMesh.default(this.geometry.clone(), new THREE.Color(this.MATERIAL_COLOR), GLOW_PROP_BAG);
    this.add(this.glow);
  }

  function update(timestamp) {
    this.position.set(this.ORBIT_RADIUS * Math.sin(this.WAVE_OFFSET + timestamp), this.ORBIT_RADIUS * Math.cos(this.WAVE_OFFSET + timestamp) + this.Y_OFFSET, 0.0);
  }
});