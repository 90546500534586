define('em-city/game/ec-rocket-trail', ['exports', 'em-city/game/ec-rocket-trail-fire', 'em-city/game/ec-rocket-trail-smoke'], function (exports, _ecRocketTrailFire, _ecRocketTrailSmoke) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var ECRocketTrail = function ECRocketTrail(startTime) {
    THREE.Object3D.call(this);
    this.name = 'rocket-trail';
    this.fire = new _ecRocketTrailFire.default(startTime);
    this.smoke = new _ecRocketTrailSmoke.default(startTime);
    this.add(this.smoke);
    this.add(this.fire);
  };

  ECRocketTrail.prototype = Object.create(THREE.Object3D.prototype);
  ECRocketTrail.prototype.constructor = ECRocketTrail;
  ECRocketTrail.prototype.animate = animate;

  exports.default = ECRocketTrail;


  function animate(timestamp, camera) {
    this.fire.animate(timestamp, camera);
    this.smoke.animate(timestamp, camera);
  }
});