define('em-city/game/ec-sabotage-smoke', ['exports', 'em-city/ember-stringify', 'em-city/game/constants'], function (exports, _emberStringify, _constants) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var NUM_PARTICLES = 250;
  var MIN_Y_SPEED = 2.0;
  var MAX_Y_SPEED = 5.0;
  var MIN_XZ_SPEED = 0.5;
  var MAX_XZ_SPEED = 1.0;
  var LIFESPAN = 8000;
  var FINAL_SMOKE_SIZE = 0.75;
  var INITIAL_SMOKE_SIZE = 0.25;
  var SIZE_VARIABILITY = 0.5;
  var SATURATION = 1.0;
  var LIGHTNESS = 0.75;
  var RADIUS = _constants.EM_CUBE_WIDTH / 2.0;

  var SMOKE_POSITION_MAP = [{ x: 0, z: RADIUS }, { x: RADIUS, z: 0 }, { x: 0, z: -RADIUS }, { x: -RADIUS, z: 0 }];

  var BASE_UNIFORMS = {
    uTime: { value: 0.0 },
    uLifespan: { value: LIFESPAN },
    uInitialSmokeSize: { value: INITIAL_SMOKE_SIZE },
    uZoom: { value: 1.0 }
  };

  var ECSabotageSmoke = function ECSabotageSmoke(startTime) {
    var uniforms = {
      uStartTime: { value: startTime },
      uTexture: { value: new THREE.TextureLoader().load(_constants.TEXTURE_PATH_CLOUD) }
    };

    Object.assign(uniforms, BASE_UNIFORMS);

    var shaderMaterial = new THREE.ShaderMaterial({
      uniforms: uniforms,
      vertexShader: _emberStringify.default['sabotage-smoke-vertex.glsl'],
      fragmentShader: _emberStringify.default['sabotage-smoke-fragment.glsl'],
      blending: THREE.NormalBlending,
      depthWrite: false,
      transparent: true
    });

    THREE.Points.call(this, geometry(), shaderMaterial);
    this.name = 'sabotage-smoke';
    this.renderOrder = 2;
  };

  ECSabotageSmoke.prototype = Object.create(THREE.Points.prototype);
  ECSabotageSmoke.prototype.constructor = ECSabotageSmoke;
  ECSabotageSmoke.prototype.animate = animate;

  function animate(timestamp, camera) {
    this.material.uniforms.uTime.value = timestamp;
    this.material.uniforms.uZoom.value = camera.zoom;
  }

  // https://threejs.org/examples/webgl_buffergeometry_custom_attributes_particles.html
  function geometry() {
    var geometry = new THREE.BufferGeometry();

    var positions = new Float32Array(NUM_PARTICLES * 3);
    var velocities = new Float32Array(NUM_PARTICLES * 3);
    var colors = new Float32Array(NUM_PARTICLES * 3);
    var sizes = new Float32Array(NUM_PARTICLES);
    var startingAges = new Float32Array(NUM_PARTICLES);

    var color = new THREE.Color();

    // Doing some wackyness to make all the smoke appear as if it's coming
    // out of one side of the cube.
    var orientation = THREE.Math.randInt(0, 3);

    for (var i = 0, i3 = 0; i < NUM_PARTICLES; i++, i3 += 3) {
      var posAndVel = randomSmokePositionAndVelocity(orientation);
      positions[i3 + 0] = posAndVel.position.x;
      positions[i3 + 1] = posAndVel.position.y;
      positions[i3 + 2] = posAndVel.position.z;

      velocities[i3 + 0] = posAndVel.velocity.x;
      velocities[i3 + 1] = posAndVel.velocity.y;
      velocities[i3 + 2] = posAndVel.velocity.z;

      color.setHSL(i / NUM_PARTICLES, SATURATION, LIGHTNESS);

      colors[i3 + 0] = color.r;
      colors[i3 + 1] = color.g;
      colors[i3 + 2] = color.b;

      sizes[i] = FINAL_SMOKE_SIZE + THREE.Math.randFloat(0, SIZE_VARIABILITY);
      startingAges[i] = THREE.Math.randFloat(0, 1.0);
    }

    geometry.addAttribute('position', new THREE.BufferAttribute(positions, 3));
    geometry.addAttribute('velocity', new THREE.BufferAttribute(velocities, 3));
    geometry.addAttribute('color', new THREE.BufferAttribute(colors, 3));
    geometry.addAttribute('size', new THREE.BufferAttribute(sizes, 1));
    geometry.addAttribute('startingAge', new THREE.BufferAttribute(startingAges, 1));

    return geometry;
  }

  exports.default = ECSabotageSmoke;


  /*
   * TOP VIEW:
   *     0       z
   *   ______    ^
   * 3 |    | 1  |__>x
   *   |____|
   *     2
   */

  function randomSmokePositionAndVelocity(orientation) {
    var velocity = newSmokeVelocity(orientation);
    velocity.y = THREE.Math.randFloat(MIN_Y_SPEED, MAX_Y_SPEED);

    var position = Object.assign({}, SMOKE_POSITION_MAP[orientation]);
    position.y = THREE.Math.randFloat(-_constants.EM_CUBE_WIDTH / 2.0, _constants.EM_CUBE_WIDTH / 2.0);

    return { position: position, velocity: velocity };
  }

  function newSmokeVelocity(orientation) {
    return [{
      x: THREE.Math.randFloat(-MAX_XZ_SPEED, MAX_XZ_SPEED),
      z: THREE.Math.randFloat(-MIN_XZ_SPEED, MAX_XZ_SPEED)
    }, {
      x: THREE.Math.randFloat(-MIN_XZ_SPEED, MAX_XZ_SPEED),
      z: THREE.Math.randFloat(-MAX_XZ_SPEED, MAX_XZ_SPEED)
    }, {
      x: THREE.Math.randFloat(-MAX_XZ_SPEED, MAX_XZ_SPEED),
      z: THREE.Math.randFloat(-MAX_XZ_SPEED, MIN_XZ_SPEED)
    }, {
      x: THREE.Math.randFloat(-MAX_XZ_SPEED, MIN_XZ_SPEED),
      z: THREE.Math.randFloat(MAX_XZ_SPEED, -MAX_XZ_SPEED)
    }][orientation];
  }
});