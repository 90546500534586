define('em-city/game/ec-rocket-trail-fire', ['exports', 'em-city/ember-stringify', 'em-city/game/constants'], function (exports, _emberStringify, _constants) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var NUM_PARTICLES = 1000;
  var MIN_Y_SPEED = -0.75;
  var MAX_Y_SPEED = -2.0;
  var MIN_XZ_SPEED = -0.1;
  var MAX_XZ_SPEED = 0.3;
  var LIFESPAN = 500;
  var FINAL_SMOKE_SIZE = 0.5;
  var INITIAL_SMOKE_SIZE = 0.05;
  var SIZE_VARIABILITY = 0.1;
  var SATURATION = 1.0;
  var LIGHTNESS = 0.75;
  var RADIUS = _constants.EM_CUBE_WIDTH / 2.0 + 0.1;

  var BASE_UNIFORMS = {
    uTime: { value: 0.0 },
    uLifespan: { value: LIFESPAN },
    uInitialSmokeSize: { value: INITIAL_SMOKE_SIZE },
    uZoom: { value: 1.0 },
    uOpacity: { value: 1.0 }
  };

  var ECRocketTrailFire = function ECRocketTrailFire(startTime) {
    var uniforms = {
      uStartTime: { value: startTime },
      uTexture: { value: new THREE.TextureLoader().load(_constants.TEXTURE_PATH_CLOUD) }
    };

    Object.assign(uniforms, BASE_UNIFORMS);

    var shaderMaterial = new THREE.ShaderMaterial({
      uniforms: uniforms,
      vertexShader: _emberStringify.default['fire-vertex.glsl'],
      fragmentShader: _emberStringify.default['fire-fragment.glsl'],
      blending: THREE.AdditiveBlending,
      depthWrite: false,
      transparent: true
    });

    THREE.Points.call(this, geometryFromRadius(RADIUS), shaderMaterial);
    this.name = 'rocket-fire';
    this.renderOrder = 2;
  };

  ECRocketTrailFire.prototype.constructor = ECRocketTrailFire;
  ECRocketTrailFire.prototype = Object.create(THREE.Points.prototype);
  ECRocketTrailFire.prototype.animate = animate;

  function animate(timestamp, camera) {
    this.material.uniforms.uTime.value = timestamp;
    this.material.uniforms.uOpacity.value = this.material.opacity;
    this.material.uniforms.uZoom.value = camera.zoom;
  }

  // https://threejs.org/examples/webgl_buffergeometry_custom_attributes_particles.html
  function geometryFromRadius(radius) {
    var geometry = new THREE.BufferGeometry();

    var positions = new Float32Array(NUM_PARTICLES * 3);
    var velocities = new Float32Array(NUM_PARTICLES * 3);
    var colors = new Float32Array(NUM_PARTICLES * 3);
    var sizes = new Float32Array(NUM_PARTICLES);
    var startingAges = new Float32Array(NUM_PARTICLES);

    var color = new THREE.Color();

    for (var i = 0, i3 = 0; i < NUM_PARTICLES; i++, i3 += 3) {
      positions[i3 + 0] = THREE.Math.randFloat(-radius, radius);
      positions[i3 + 1] = THREE.Math.randFloat(-_constants.EM_CUBE_WIDTH / 2.0, _constants.EM_CUBE_WIDTH / 2.0);
      positions[i3 + 2] = THREE.Math.randFloat(-radius, radius);

      var xSign = Math.sign(positions[i3 + 0]);
      var zSign = Math.sign(positions[i3 + 2]);
      velocities[i3 + 0] = xSign * THREE.Math.randFloat(MIN_XZ_SPEED, MAX_XZ_SPEED);
      velocities[i3 + 1] = THREE.Math.randFloat(MIN_Y_SPEED, MAX_Y_SPEED);
      velocities[i3 + 2] = zSign * THREE.Math.randFloat(MIN_XZ_SPEED, MAX_XZ_SPEED);

      color.setHSL(i / NUM_PARTICLES, SATURATION, LIGHTNESS);

      colors[i3 + 0] = color.r;
      colors[i3 + 1] = color.g;
      colors[i3 + 2] = color.b;

      sizes[i] = FINAL_SMOKE_SIZE + THREE.Math.randFloat(0, SIZE_VARIABILITY);
      startingAges[i] = THREE.Math.randFloat(0, 1.0);
    }

    geometry.addAttribute('position', new THREE.BufferAttribute(positions, 3));
    geometry.addAttribute('velocity', new THREE.BufferAttribute(velocities, 3));
    geometry.addAttribute('color', new THREE.BufferAttribute(colors, 3));
    geometry.addAttribute('size', new THREE.BufferAttribute(sizes, 1));
    geometry.addAttribute('startingAge', new THREE.BufferAttribute(startingAges, 1));

    return geometry;
  }

  exports.default = ECRocketTrailFire;
});