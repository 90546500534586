define('em-city/controllers/play/sector', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend({
    game: Ember.inject.service(),
    sectorName: Ember.computed('model', function () {
      var game = this.get('game');
      var sectorId = this.get('model');
      return game.squareForIndex(sectorId).sectorName;
    })
  });
});