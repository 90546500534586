define('em-city/components/play-again', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    classNames: ['text-center'],
    game: Ember.inject.service(),

    actions: {
      playAgain: function playAgain() {
        this.get('game').reset();
      }
    }
  });
});