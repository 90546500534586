define('em-city/game/sector-visual', ['exports', 'em-city/game/ec-renderer', 'em-city/game/ec-scene', 'em-city/game/ec-camera', 'em-city/game/ec-ambient-light', 'em-city/game/ec-directional-light', 'em-city/game/game-loop', 'em-city/game/constants'], function (exports, _ecRenderer, _ecScene, _ecCamera, _ecAmbientLight, _ecDirectionalLight, _gameLoop, _constants) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  function _classCallCheck(instance, Constructor) {
    if (!(instance instanceof Constructor)) {
      throw new TypeError("Cannot call a class as a function");
    }
  }

  var _createClass = function () {
    function defineProperties(target, props) {
      for (var i = 0; i < props.length; i++) {
        var descriptor = props[i];
        descriptor.enumerable = descriptor.enumerable || false;
        descriptor.configurable = true;
        if ("value" in descriptor) descriptor.writable = true;
        Object.defineProperty(target, descriptor.key, descriptor);
      }
    }

    return function (Constructor, protoProps, staticProps) {
      if (protoProps) defineProperties(Constructor.prototype, protoProps);
      if (staticProps) defineProperties(Constructor, staticProps);
      return Constructor;
    };
  }();

  var Y_OFFSET = -3.0;

  var SectorVisual = function () {
    function SectorVisual() {
      _classCallCheck(this, SectorVisual);

      this.configureCamera();
      this.configureScene();
      this.configureGameLoop();
      this.renderer = new _ecRenderer.default();
      this.canvas = this.renderer.domElement;
      this.configureInput();

      this.square = null;
      this.requestId = null;
    }

    _createClass(SectorVisual, [{
      key: 'configureCamera',
      value: function configureCamera() {
        this.camera = new _ecCamera.default();
        this.camera.lookAt(new THREE.Vector3(0, 0, 0));
      }
    }, {
      key: 'configureInput',
      value: function configureInput() {
        this.controls = new THREE.OrbitControls(this.camera, this.canvas);
        this.controls.minZoom = _constants.MIN_ZOOM;
        this.controls.maxZoom = _constants.MAX_ZOOM;
      }
    }, {
      key: 'configureScene',
      value: function configureScene() {
        this.scene = new _ecScene.default();
        this.scene.add(this.camera);
        this.addLights();
      }
    }, {
      key: 'configureGameLoop',
      value: function configureGameLoop() {
        this.render = render.bind(this);
        this.gameLoop = (0, _gameLoop.default)(_constants.MS_PER_UPDATE, animationFunction, this);
      }
    }, {
      key: 'configureSquare',
      value: function configureSquare(square, backgroundColor) {
        this.scene.remove(this.square);
        this.square = square.clone();
        this.square.position.set(0, Y_OFFSET, 0);
        this.scene.add(this.square);
        this.configureBackground(backgroundColor);
      }
    }, {
      key: 'configureBackground',
      value: function configureBackground(backgroundColor) {
        this.scene.background = backgroundColor;
      }
    }, {
      key: 'resize',
      value: function resize(dimensions) {
        this.renderer.scaleWithDimensions(dimensions);
        this.scene.scaleWithDimensions(this.renderer.dimensions);
        this.camera.updateWithScaledDimensions(this.renderer.dimensions, this.scene.scale.x);
      }
    }, {
      key: 'addLights',
      value: function addLights() {
        this.scene.add(new _ecAmbientLight.default());
        this.scene.add(new _ecDirectionalLight.default());
      }
    }, {
      key: 'start',
      value: function start() {
        this.requestId = requestAnimationFrame(this.render);
        this.controls.reset();
      }
    }, {
      key: 'stop',
      value: function stop() {
        cancelAnimationFrame(this.requestId);
        this.requestId = null;
      }
    }]);

    return SectorVisual;
  }();

  exports.default = SectorVisual;


  function render(timestamp) {
    this.requestId = requestAnimationFrame(this.render);
    this.gameLoop(timestamp);

    this.renderer.render(this.scene, this.camera, false);
  }

  function animationFunction(timestamp) {
    if (!this.square) return;
    this.square.rotation.y += 0.01;
    this.square.animate(timestamp, this.camera);
  }
});