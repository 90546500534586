define('em-city/game/model-downloader', ['exports', 'em-city/game/ec-building', 'em-city/config/environment'], function (exports, _ecBuilding, _environment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.downloadMeshes = undefined;


  var DOWNLOADS = [{
    type: 'mesh',
    buildingName: 'diamond-building',
    path: 'models/diamond-building.json',
    klass: _ecBuilding.default
  }, {
    type: 'mesh',
    buildingName: 'hancock-building',
    path: 'models/hancock-building.json',
    klass: _ecBuilding.default
  }, {
    type: 'mesh',
    buildingName: 'sears-building',
    path: 'models/sears-building.json',
    klass: _ecBuilding.default
  }];

  function downloadMeshes() {
    return downloadModels(DOWNLOADS).then(function (datas) {
      return downloadsPlusMeshes(DOWNLOADS, datas);
    });
  }

  function downloadModels(downloads) {
    return Ember.RSVP.Promise.all(downloads.map(function (download) {
      return downloadModel(download.path, download.buildingName);
    }));
  }

  function downloadModel(path, buildingName) {
    path = '/' + path;
    var loader = new THREE.JSONLoader();

    return new Ember.RSVP.Promise(function (resolve, reject) {
      loader.load(path, function (geometry) {
        return resolve({ geometry: geometry, buildingName: buildingName });
      }, function () {}, function (error) {
        return reject(error);
      });
    });
  }

  function downloadsPlusMeshes(downloads, datas) {
    return downloads.map(function (download, index) {
      var _datas$index = datas[index],
          geometry = _datas$index.geometry,
          buildingName = _datas$index.buildingName;


      return Object.assign({}, download, {
        mesh: new downloads[index]['klass'](geometry, buildingName)
      });
    });
  }

  exports.downloadMeshes = downloadMeshes;
});