define('em-city/game/ec-em-cube', ['exports', 'em-city/game/ec-glow-mesh', 'em-city/game/ec-fire', 'em-city/game/ec-sabotage-smoke', 'em-city/game/constants'], function (exports, _ecGlowMesh, _ecFire, _ecSabotageSmoke, _constants) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var COLOR = 0x439E47;
  var BUSTED_COLOR = _constants.ROAD_COLOR;
  var BLINK_COLOR = 0xFFD700;
  var MAX_BLINKING_PERCENT_CHANCE = 0.10;
  var CHANCE_OF_UNBLINK = 0.05;
  var SHININESS = 1000;

  var START_ON_FIRE_OFFSET = 20;
  var START_ON_FIRE_DIVISOR = 2;
  var SABOTAGE_OFFSET = 20;
  var SABOTAGE_DIVISOR = 2;

  var GLOW_PROP_BAG = {
    p: 4.0,
    scale: 3.0
  };

  var MAX_HEAT = 8;
  var MIN_HEAT = 4;

  var MAX_PRODUCTION = 4;
  var MIN_PRODUCTION = 2;

  var MAX_INSURRECTION = 2;
  var MIN_INSURRECTION = 1;

  var UNIT_INDEXES = [0, 1, 2, 3];

  var ECEmCube = function ECEmCube() {
    var geometry = new THREE.BoxGeometry(_constants.EM_CUBE_WIDTH, _constants.EM_CUBE_WIDTH, _constants.EM_CUBE_WIDTH);
    var material = new THREE.MeshPhongMaterial({
      color: COLOR,
      shininess: SHININESS,
      transparent: false
    });

    THREE.Mesh.call(this, geometry, material);

    geometry.computeBoundingBox();
    this.height = this.geometry.boundingBox.getSize().y;

    this.castShadow = false;
    this.receiveShadow = false;
    this.name = 'em-cube';
    this.square = null;
    this.touchable = true;
    this.heat = THREE.Math.randInt(MIN_HEAT, MAX_HEAT);
    this._production = THREE.Math.randInt(MIN_PRODUCTION, MAX_PRODUCTION);
    this.insurrection = THREE.Math.randInt(MIN_INSURRECTION, MAX_INSURRECTION);
    this.unhighlightColorHex = COLOR;
    this.cameraVector = new THREE.Vector3();

    this.propMap = { heat: 0, insurrection: 0 };
    this.overheated = false;
    this.sabotaged = false;
    this.dontBreakNextTurn = true;
  };

  ECEmCube.prototype = Object.create(THREE.Mesh.prototype);
  ECEmCube.prototype.constructor = ECEmCube;
  ECEmCube.prototype.highlight = highlight;
  ECEmCube.prototype.unhighlight = unhighlight;
  ECEmCube.prototype.addUnit = addUnit;
  ECEmCube.prototype.removeUnit = removeUnit;
  ECEmCube.prototype.animate = animate;
  ECEmCube.prototype.viewVector = new THREE.Vector3();
  ECEmCube.prototype.clone = clone;
  ECEmCube.prototype.extinguishFire = extinguishFire;
  ECEmCube.prototype.maybeStartOnFire = maybeStartOnFire;
  ECEmCube.prototype.startOnFire = startOnFire;
  ECEmCube.prototype.sabotage = sabotage;
  ECEmCube.prototype.repairSabotage = repairSabotage;
  ECEmCube.prototype.maybeSabotage = maybeSabotage;
  ECEmCube.prototype.maybeBust = maybeBust;
  ECEmCube.prototype.unbust = unbust;
  ECEmCube.prototype.createEffects = createEffects;
  ECEmCube.prototype.percentChanceOfStartingOnFire = percentChanceOfStartingOnFire;
  ECEmCube.prototype.percentChanceOfBeingSabotaged = percentChanceOfBeingSabotaged;
  ECEmCube.prototype.updateRates = updateRates;

  Object.defineProperty(ECEmCube.prototype, 'availableUnitIndex', { get: availableUnitIndex });

  Object.defineProperty(ECEmCube.prototype, 'physicsProps', { get: physicsProps });

  Object.defineProperty(ECEmCube.prototype, 'production', { get: production });

  Object.defineProperty(ECEmCube.prototype, 'busted', { get: busted });

  Object.defineProperty(ECEmCube.prototype, 'units', { get: units });

  Object.defineProperty(ECEmCube.prototype, 'fire', { get: fire });

  Object.defineProperty(ECEmCube.prototype, 'glow', { get: glow });

  Object.defineProperty(ECEmCube.prototype, 'sabotageSmoke', { get: sabotageSmoke });

  Object.defineProperty(ECEmCube.prototype, 'serialized', { get: serialized });

  exports.default = ECEmCube;


  function availableUnitIndex() {
    var usedIndexes = this.units.map(function (unit) {
      return unit.orientationIndex;
    });
    return UNIT_INDEXES.find(function (index) {
      return usedIndexes.indexOf(index) === -1;
    });
  }

  function physicsProps() {
    return {
      type: 'box',
      size: [_constants.EM_CUBE_WIDTH, _constants.EM_CUBE_WIDTH, _constants.EM_CUBE_WIDTH],
      move: true,
      density: 1.0
    };
  }

  function addUnit(unit) {
    unit.square = this.square;
    unit.em = this;
    this.add(unit);
  }

  function removeUnit(unitToRemove) {
    unitToRemove.square = null;
    unitToRemove.em = null;
    this.remove(unitToRemove);
  }

  function highlight() {
    this.material.color.setHex(_constants.HIGHLIGHT_COLOR);
  }

  function unhighlight() {
    this.material.color.setHex(this.unhighlightColorHex);
  }

  function animate(timestamp, camera) {
    this.units.forEach(function (unit) {
      return unit.animate(timestamp);
    });
    blinkLight(this, timestamp);
    flickerFlame(this, timestamp, camera);
    billowSabotageSmoke(this, timestamp, camera);

    updateGlowUniform(this, camera);
    updatePosition(this);
  }

  function updatePosition(context) {
    if (!context.body) return;
    context.position.copy(context.body.getPosition());
    context.quaternion.copy(context.body.getQuaternion());
  }

  function updateGlowUniform(context, camera) {
    context.cameraVector.copy(camera.getWorldPosition());
    context.worldToLocal(context.cameraVector);
    context.viewVector.subVectors(context.cameraVector, context.position);
    context.glow.material.uniforms.viewVector.value = context.viewVector;
  }

  function blinkLight(context) {
    var colorHex = context.unhighlightColorHex;
    if (shouldBlink(context, colorHex)) {
      blink(context);
    } else if (shouldUnblink(context, colorHex)) {
      unblink(context);
    }
  }

  function blink(context) {
    context.unhighlightColorHex = BLINK_COLOR;
    context.glow.visible = true;
  }

  function unblink(context) {
    context.unhighlightColorHex = COLOR;
    if (context.glow) context.glow.visible = false;
  }

  function shouldBlink(context, colorHex) {
    var chanceOfBlink = context.propMap.production / 100 * MAX_BLINKING_PERCENT_CHANCE;
    var unblinked = colorHex === COLOR;
    var lucky = Math.random() < chanceOfBlink;
    return unblinked && lucky && !context.busted;
  }

  function shouldUnblink(context, colorHex) {
    var blinked = colorHex === BLINK_COLOR;
    var lucky = Math.random() < CHANCE_OF_UNBLINK;
    return blinked && (lucky || context.busted);
  }

  function flickerFlame(context, timestamp, camera) {
    if (context.overheated) {
      context.fire.visible = true;
      context.fire.animate(timestamp, camera);
      context.unhighlightColorHex = BUSTED_COLOR;
    } else {
      context.fire.visible = false;
    }
  }

  function billowSabotageSmoke(context, timestamp, camera) {
    if (context.sabotaged) {
      context.sabotageSmoke.visible = true;
      context.sabotageSmoke.animate(timestamp, camera);
      context.unhighlightColorHex = BUSTED_COLOR;
    } else {
      context.sabotageSmoke.visible = false;
    }
  }

  function createGlow(context, colorHex, propBag) {
    var glow = new _ecGlowMesh.default(context.geometry.clone(), new THREE.Color(colorHex), propBag);

    glow.visible = false;

    context.add(glow);
  }

  function createFire(context) {
    var fire = new _ecFire.default();
    fire.visible = false;
    context.add(fire);
  }

  function createSabotageSmoke(context) {
    var sabotageSmoke = new _ecSabotageSmoke.default();
    sabotageSmoke.visible = false;
    context.add(sabotageSmoke);
  }

  function clone() {
    var clone = new this.constructor().copy(this);
    clone.overheated = this.overheated;
    clone.sabotaged = this.sabotaged;
    configureCloneColor(this, clone);
    return clone;
  }

  function configureCloneColor(original, clone) {
    var color = COLOR;
    if (original.sabotaged || original.overheated) color = BUSTED_COLOR;
    clone.material.color.setHex(color);
  }

  function percentChanceOfStartingOnFire(heat) {
    var chance = (heat - START_ON_FIRE_OFFSET) / 100 / START_ON_FIRE_DIVISOR;
    return Math.max(chance, 0);
  }

  function percentChanceOfBeingSabotaged(insurrection) {
    var chance = (insurrection - SABOTAGE_OFFSET) / 100 / SABOTAGE_DIVISOR;
    return Math.max(chance, 0);
  }

  function production() {
    if (this.busted) return 0;
    return this._production;
  }

  function sabotage() {
    this.sabotaged = true;
  }

  function startOnFire() {
    this.overheated = true;
  }

  function maybeStartOnFire(heat) {
    if (this.busted) return false;
    var chanceOfFire = this.percentChanceOfStartingOnFire(heat);
    if (Math.random() <= chanceOfFire) this.startOnFire();
    return this.overheated;
  }

  function maybeSabotage(insurrection) {
    if (this.busted) return false;
    var chanceOfSabotage = this.percentChanceOfBeingSabotaged(insurrection);
    if (Math.random() <= chanceOfSabotage) this.sabotage();
    return this.sabotaged;
  }

  function maybeBust() {
    if (this.dontBreakNextTurn) {
      this.dontBreakNextTurn = false;
      return;
    }

    var onFire = this.maybeStartOnFire(this.propMap.heat);
    var sabotaged = this.maybeSabotage(this.propMap.isurrection);

    return onFire || sabotaged;
  }

  function extinguishFire() {
    this.unbust();
  }

  function repairSabotage() {
    this.unbust();
  }

  function busted() {
    return this.sabotaged || this.overheated;
  }

  function unbust() {
    this.sabotaged = false;
    this.overheated = false;
    this.dontBreakNextTurn = false;
    this.unhighlightColorHex = COLOR;
  }

  function sabotageSmoke() {
    return this.children.find(function (child) {
      return isSabotageSmoke(child);
    });
  }

  function isSabotageSmoke(object) {
    return object.name === 'sabotage-smoke';
  }

  function fire() {
    return this.children.find(function (child) {
      return isFire(child);
    });
  }

  function isFire(object) {
    return object.name === 'fire';
  }

  function glow() {
    return this.children.find(function (child) {
      return isGlow(child);
    });
  }

  function isGlow(object) {
    return object.name === 'glow';
  }

  function units() {
    return this.children.filter(function (child) {
      return isUnit(child);
    });
  }

  function isUnit(object) {
    return ['cooling-unit', 'security-unit'].includes(object.name);
  }

  function createEffects() {
    createGlow(this, BLINK_COLOR, GLOW_PROP_BAG);
    createFire(this);
    createSabotageSmoke(this);
  }

  function updateRates(propMap) {
    var limitedPropMap = {
      heat: propMap.heat,
      insurrection: propMap.insurrection
    };
    Object.assign(this.propMap, limitedPropMap);
  }

  function serialized() {
    return {
      properties: {
        sabotaged: this.sabotaged,
        overheated: this.overheated,
        heat: this.heat,
        insurrection: this.insurrection,
        _production: this._production,
        dontBreakNextTurn: this.dontBreakNextTurn,
        propMap: Object.assign({}, this.propMap)
      },
      units: this.units.map(function (unit) {
        return unit.serialized;
      })
    };
  }
});