define('em-city/game/ec-ground', ['exports', 'em-city/game/grid-configurer', 'em-city/game/util', 'em-city/game/constants'], function (exports, _gridConfigurer, _util, _constants) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var MIN_HEAT = 5;
  var MAX_HEAT = 15;
  var MIN_INSURRECTION = 1;
  var MAX_INSURRECTION = 15;
  var Y_OFFSET = -3.0;

  var ECGround = function ECGround() {
    THREE.Object3D.call(this);
    this.name = 'ground';
    this.squares = [];
    this.configureGrid(_constants.ROWS);
    this.position.set(0.0, Y_OFFSET, 0.0);
    this.finalPosition = this.position.clone();
    this.heat = THREE.Math.randInt(MIN_HEAT, MAX_HEAT);
    this.insurrection = THREE.Math.randInt(MIN_INSURRECTION, MAX_INSURRECTION);
    this.physicsProps = {
      type: 'box',
      size: [_constants.GROUND_WIDTH, _constants.GROUND_SQUARE_HEIGHT, _constants.GROUND_WIDTH],
      pos: [0, -_constants.GROUND_SQUARE_HEIGHT, 0],
      move: false
    };
  };

  ECGround.prototype = Object.create(THREE.Object3D.prototype);
  ECGround.prototype.constructor = ECGround;
  ECGround.prototype.configureGrid = _gridConfigurer.configureGrid;
  ECGround.prototype.animate = animate;
  ECGround.prototype.deserialize = deserialize;

  Object.defineProperty(ECGround.prototype, 'emCubes', { get: emCubes });
  Object.defineProperty(ECGround.prototype, 'squaresWithCubes', { get: squaresWithCubes });
  Object.defineProperty(ECGround.prototype, 'coolingUnits', { get: coolingUnits });
  Object.defineProperty(ECGround.prototype, 'securityUnits', { get: securityUnits });
  Object.defineProperty(ECGround.prototype, 'animatables', { get: _util.animatables });
  Object.defineProperty(ECGround.prototype, 'serialized', { get: serialized });

  exports.default = ECGround;


  function emCubes() {
    return (0, _util.flatten)(this.squares.map(function (square) {
      return square.emCubes;
    }));
  }

  function squaresWithCubes() {
    return this.squares.filter(function (square) {
      return square.emCubes.length;
    });
  }

  function coolingUnits() {
    return (0, _util.flatten)(this.squares.map(function (square) {
      return square.coolingUnits;
    }));
  }

  function securityUnits() {
    return (0, _util.flatten)(this.squares.map(function (square) {
      return square.securityUnits;
    }));
  }

  function animate(timestamp, camera) {
    (0, _util.animateChildren)(this, timestamp, camera);
  }

  function serialized() {
    return {
      properties: {
        heat: this.heat,
        insurrection: this.insurrection
      },
      squares: this.squares.map(function (square) {
        return square.serialized;
      })
    };
  }

  function deserialize(groundState) {
    (0, _util.deserializeProperties)(this, groundState);
  }
});